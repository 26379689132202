import React from "react"
import tw, { styled } from "twin.macro"

export const Type = styled.div``

export const InstallRenovation = styled.div``

export const NewConstruction = styled.div``

export const Purchasing = styled.div``

export const Wrapper = styled.div`
  // width: 75%;
  margin: 0 auto;
  h1,
  h2,
  h3 {
    text-align: center;
  }
`

export const Choice = styled.button`
  background: #cabfab;
  padding: 0px 0px;
  font-size: 24px;
  width: 250px;
  height: 50px;
  border-radius: 30px;
  &:hover {
    color: #ffffff;
    background: #23395b;
  }
`

export const ChoiceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: 25px;
  }
  grid-column-gap: 50px;
  justify-items: center;
  text-align: center;
  h2 {
    font-weight: 900;
  }
`

export const ChoiceGridThree = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 50px;
  text-align: center;
  h2 {
    font-weight: 900;
  }
`
export const ChoiceGridFour = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 50px;
  text-align: center;
  @media (max-width: 1550px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 25px;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: 25px;
  }
  h2 {
    height: 100px;
    font-weight: 900;
  }
`

export const Back = styled.button`
  background: blue;
  padding: 25px;
  margin: 25px auto;
  color: #ffffff;
  display: block;
  border-radius: 15px;
`

export const Next = styled.button`
  background: green;
  padding: 25px;
  margin: 25px auto;
  color: #ffffff;
  display: block;
  border-radius: 15px;
`

export const FormContractBuilderAStyle = styled.form`
  display: grid;
  //background: grey;
  input {
    //background: lightgrey;
    &:focus {
      outline: none;
    }
  }
`

export const NavGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const Form = styled.form`
  display: grid;
  div.gridMe {
    grid-template-columns: 1fr 1fr;
    display: grid;
    width: 750px;
    margin: 0 auto;
    @media (max-width: 1024px) {
      width: 90%;
      margin: 0 auto;
      grid-template-columns: 1fr;
      .captchaStyle {
        margin: 0 auto;
      }
      input {
        width: 50%;
        padding: 25px;
        margin: 25px auto;
      }
      input.enabled {
        background: green;
        color: #fff;
      }
    }
  }
`

export const StatusWrapper = styled.div`
  h1,
  h2,
  h3 {
    text-align: center;
  }
`

export const Choices = styled.div`
  p {
    text-align: center;
    span {
      font-weight: 900;
    }
  }
`

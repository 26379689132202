import React, { useEffect, useState } from "react"
import LayoutAlt from "../components/layoutAlt"
import tw, { styled } from "twin.macro"
import Quote from "../components/quote-2"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Header1 = styled.h1`
  margin-top: 250px;
  @media (max-width: 650px) {
    margin-top: 115px;
  }
  text-align: center;
`

const Estimates = ({ data }) => {
  // Implement later on

  const isBrowser = typeof window !== `undefined`

  const [finishStatus, setfinishStatus] = useState(false)
  useEffect(() => {
    if (isBrowser) {
      if (window.location.host !== "localhost:8888") {
        const onBackButtonEvent = e => {
          e.preventDefault()
          if (!finishStatus) {
            if (
              window.confirm(
                "Are you sure you want to leave? you will lose you form data."
              )
            ) {
              setfinishStatus(true)
              // your logic
              window.history.go(-1)
              window.removeEventListener("popstate", onBackButtonEvent)
            } else {
              window.history.pushState(null, null, window.location.pathname)
              setfinishStatus(false)
            }
          }
        }
        const handleBeforeUnload = e => {
          e.preventDefault()
          const message =
            "Are you sure you want to leave? you will lose you form data."
          e.returnValue = message
          return message
        }
        window.addEventListener("beforeunload", handleBeforeUnload)
        window.history.pushState(null, null, window.location.pathname)
        window.addEventListener("popstate", onBackButtonEvent)
        return () => {
          window.removeEventListener("popstate", onBackButtonEvent)
          window.removeEventListener("beforeunload", handleBeforeUnload)
        }
      }
    }
  }, [])
  return (
    <LayoutAlt>
      <SEO
        title={data.sanityQuote.seo && data.sanityQuote.seo.title}
        website={data.sanityQuote.seo && data.sanityQuote.seo.website}
        description={data.sanityQuote.seo && data.sanityQuote.seo.description}
        keywords={data.sanityQuote.seo && data.sanityQuote.seo.keywords}
        image={
          data.sanityQuote.seo.socialShareImage.asset &&
          data.sanityQuote.seo.socialShareImage.asset.url
        }
      />
      <Header1>Request A Quote</Header1>
      <Quote />
    </LayoutAlt>
  )
}

export default Estimates

export const query = graphql`
  query EstimatesPageQuery {
    sanityQuote {
      seo {
        title
        keywords
        website
        description
        socialShareImage {
          asset {
            url
          }
        }
      }
    }
  }
`

import React from "react"
import tw, { styled } from "twin.macro"

export const PageContain = styled.section`
  ${tw`max-w-6xl mx-auto`}
  p {
    ${tw`max-w-3xl m-auto p-4`}
    margin-bottom:50px;
  }
`

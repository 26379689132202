import BlockContent from "@sanity/block-content-to-react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import tw, { styled } from "twin.macro"
import LayoutAlt from "../components/layoutAlt"
import { PageContain } from "../components/page-contain"
import Seo from "../components/seo"

const Header1 = styled.h1`
  margin-top: 150px;
  text-align: center;
`
const Header2 = styled.h2`
  text-align: center;
`
const Header3 = styled.h3`
  text-align: center;
`
const Header4 = styled.h4`
  text-align: left;
  ${tw`max-w-3xl m-auto p-4`}
`

const Grid = styled.div`
  ${tw`grid xl:grid-cols-2 gap-5 justify-items-center`}
  div {
    width: 100%;
  }
`

const Finishing = ({ data }) => {
  // data.sanityOnSiteFinishing.basicImage.map(asset => {
  //   console.log(asset)
  // })

  const serializers = {
    types: {
      justContent: props => <>{props.node.description}</>,
    },
  }
  return (
    <LayoutAlt>
      <Seo
        title={
          data.sanityOnSiteFinishing.seo && data.sanityOnSiteFinishing.seo.title
        }
        keywords={
          data.sanityOnSiteFinishing.seo &&
          data.sanityOnSiteFinishing.seo.keywords
        }
        website={
          data.sanityOnSiteFinishing.seo &&
          data.sanityOnSiteFinishing.seo.website
        }
        description={
          data.sanityOnSiteFinishing.seo &&
          data.sanityOnSiteFinishing.seo.description
        }
        image={
          data.sanityOnSiteFinishing.seo.socialShareImage.asset &&
          data.sanityOnSiteFinishing.seo.socialShareImage.asset.url
        }
      />
      <PageContain>
        <Header1>{data.sanityOnSiteFinishing.title}</Header1>
        <Header2>{data.sanityOnSiteFinishing.subTitle}</Header2>
        <Header3>{data.sanityOnSiteFinishing.description}</Header3>
        <Header4>{data.sanityOnSiteFinishing.secondTitle}</Header4>
        <BlockContent
          blocks={data.sanityOnSiteFinishing._rawSecondDescription.explanation}
          serializers={serializers}
        />
        <Grid>
          {data.sanityOnSiteFinishing.basicImage.map(image => {
            return <GatsbyImage image={image.asset.gatsbyImageData} />
          })}
        </Grid>
      </PageContain>
    </LayoutAlt>
  )
}

export default Finishing

export const query = graphql`
  query FinishingPageQuery {
    sanityOnSiteFinishing {
      seo {
        website
        title
        description
        keywords
        socialShareImage {
          asset {
            url
          }
        }
      }
      title
      subTitle
      description
      secondTitle
      _rawSecondDescription(resolveReferences: { maxDepth: 10 })
      basicImage {
        asset {
          gatsbyImageData(width: 650)
        }
      }
    }
  }
`

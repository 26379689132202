import React, { useEffect } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import BlockContent from "@sanity/block-content-to-react"

const FlooringWrap = styled.section`
  .floor-indi {
    .gatsby-image-wrapper {
      height: 600px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
    align-items: center;
    justify-items: center;
    div {
      width: 100%;
      .block {
        width: 75%;
        p {
          ${tw`lg:w-3/4`}
        }
        margin: 0 auto;
        @media (max-width: 1200px) {
          padding: 100px 0;
        }
      }
      .quotey {
        display: inline-block;
        border-radius: 30px;
        padding: 10px;
        background: #23395b;
        color: #ffffff;
      }
    }
    .direction-l {
      order: 2;
      @media (max-width: 1200px) {
        order: 1;
      }
    }
    .direction-r {
      @media (max-width: 1200px) {
        order: 2;
      }
    }
  }
`

const Flooring = ({ children }) => {
  const serializers = {
    types: {
      justContent: props => <>{props.node.description}</>,
    },
  }
  return (
    <StaticQuery
      query={graphql`
        query FlooringQuery {
          allSanityFlooring(sort: { order: ASC, fields: order }) {
            edges {
              node {
                _id
                floorType
                _rawFloorTypeDesc
                buttonTitle
                floorImage {
                  alt
                  asset {
                    gatsbyImageData(layout: FULL_WIDTH, width: 700)
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const dashed_products = data.allSanityFlooring.edges.map(
          ({ node: product }) => {
            let str = product.floorType
            str = str.replace(/\s+/g, "-").toLowerCase()
            return {
              floorType: product.floorType,
              _id: product._id,
              floorTypeSpaces: str.toLowerCase(),
              image: product.floorImage.asset.gatsbyImageData,
              _rawFloorTypeDesc: product._rawFloorTypeDesc,
              buttonTitle: product.buttonTitle,
            }
          }
        )

        return (
          <>
            {children}
            <>
              <FlooringWrap>
                {/* test
                <pre>{JSON.stringify(dashed_products, null, 2)}</pre> */}
                {dashed_products.map((floor, i) => (
                  <div className="floor-indi" key={floor._id}>
                    {floor.image && (
                      <>
                        <div
                          id={floor.floorType}
                          className={"direction-" + (i % 2 ? "r" : "l")}
                        >
                          {floor.image && (
                            <Link to={`/products/${floor.floorTypeSpaces}`}>
                              <GatsbyImage image={floor.image} />
                            </Link>
                          )}
                        </div>
                        <div>
                          <div className="block">
                            <h1>
                              <Link to={`/products/${floor.floorTypeSpaces}`}>
                                {floor.floorType}
                              </Link>
                            </h1>
                            {/* <pre>
                test
            {JSON.stringify(floor.floorImage.asset.gatsbyImageData, null, 2)}
            </pre> */}
                            {/* <p>{floor.floorTypeDesc}</p> */}
                            {floor._rawFloorTypeDesc && (
                              <BlockContent
                                serializers={serializers}
                                blocks={floor._rawFloorTypeDesc.explanation}
                              />
                            )}
                            {/* blockcontent */}
                            <Link
                              className="quotey"
                              to={`/products/${floor.floorTypeSpaces}`}
                            >
                              {floor.buttonTitle}
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </FlooringWrap>
            </>
          </>
        )
      }}
    />
  )
}

export default Flooring

import React, { useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { StaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import "react-datepicker/dist/react-datepicker.css"
import FormFinal from "./FormFinal"
import FormA from "./FormA"
import ReCAPTCHA from "react-google-recaptcha"
import StatusComp from "./Status"
import {
  Type,
  InstallRenovation,
  NewConstruction,
  Purchasing,
  Wrapper,
  Choice,
  ChoiceGrid,
  ChoiceGridFour,
  ChoiceGridThree,
  Back,
  Next,
  FormContractBuilderAStyle,
  NavGrid,
  Form,
  StatusWrapper,
  Choices,
  Status,
} from "./quoteStyles"

const Quote = () => {
  const [type, setType] = useState(true)

  // renovation
  const [installRenovation, setInstallRenovation] = useState(false)
  const [renovationContractorForm, setRenovationContractorForm] = useState(
    false
  )
  const [renovationHomeownerFinal, setRenovationHomeownerFinal] = useState(
    false
  )
  const [renovationContractorFinal, setRenovationContractorFinal] = useState(
    false
  )

  const [renovationAbsoluteFinal, setRenovationAbsoluteFinal] = useState(false)

  const [renovationNotsureFinal, setRenovationNotsureFinal] = useState(false)

  // new construction

  const [newConstruction, setNewConstruction] = useState(false)

  const [constructionContractorForm, setConstructionContractorForm] = useState(
    false
  )

  const [constructionHomeownerFinal, setConstructionHomeownerFinal] = useState(
    false
  )

  const [
    constructionContractorFormData,
    setConstructionContractorFormData,
  ] = useState({})

  const [
    constructionContractorFinal,
    setConstructionContractorFinal,
  ] = useState(false)

  const [constructionAbsoluteFinal, setConstructionAbsoluteFinal] = useState(
    false
  )

  const [constructionNotSureFinal, setConstructionNotSureFinal] = useState(
    false
  )

  const [choices, setChoices] = useState({})
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loading3, setLoading3] = useState(false)
  const [loading5, setLoading5] = useState(false)
  const [loading6, setLoading6] = useState(false)
  const [loading7, setLoading7] = useState(false)
  const [loading8, setLoading8] = useState(false)
  const [loading9, setLoading9] = useState(false)
  const [captchaValue, setCaptchaValue] = useState(null)
  const [captchaFailed, setCaptchaFailed] = useState(false)
  const [nextStage, setNextStage] = useState(false)
  const [submitButton, setSubmitButton] = useState(true)
  const [failedEmail, SetFailedEmail] = useState(false)

  //data

  // renovation

  const [
    homeOwnerRenovationFinalData,
    setHomeOwnerRenovationFinalData,
  ] = useState({})

  const [
    renovationContractorFormData,
    setRenovationContractorFormData,
  ] = useState({})

  const [
    renovationContractorFinalData,
    setRenovationContractorFinalData,
  ] = useState({})

  const [
    renovationAbsoluteFinalData,
    setRenovationAbsoluteFinalData,
  ] = useState({})

  const [renovationNotsureFinalData, setRenovationNotsureFinalData] = useState(
    {}
  )

  // construction

  const [
    homeOwnerConstructionFinalData,
    setHomeOwnerConstructionFinalData,
  ] = useState({})

  const [
    contractorConstructionFinalData,
    setContractorConstructionFinalData,
  ] = useState({})

  const [
    contractorAbsoluteFinalData,
    setContractorAbsoluteFinalData,
  ] = useState({})

  const [contractorNotsureFinalData, setContractorNotsureFinalData] = useState(
    {}
  )

  //----------------------------------------------------------------------------------------------------
  // Fetch Url

  const isBrowser = typeof window !== `undefined`
  let location
  if (isBrowser) {
    location = window.location.hostname
  }
  let fetchUrl
  if (location === "localhost") {
    fetchUrl = `http://localhost:8888/.netlify/functions/estimate-submit`
  } else if (
    location === "website-gatsby-4821585328.gtsb.io" ||
    location === "dev.absolutehardwood.ca"
  ) {
    fetchUrl = `https://absolute-hardwood-dev-static.netlify.app/.netlify/functions/estimate-submit`
  } else {
    fetchUrl = "/.netlify/functions/estimate-submit"
  }

  // What type of project are you tackling?
  //----------------------------------------------------------------------------------------------------

  // # 1 Clicked renovation

  const renovation = () => {
    setType(false)
    setInstallRenovation(true)
    setChoices({
      project: "Renovation",
    })
  }

  // Clicked back

  const backInstallRenovation = () => {
    setType(true)
    setInstallRenovation(false)
    setChoices({})
  }

  // #1 a Clicked Homeowner

  const renovationHomeowner = () => {
    setInstallRenovation(false)
    setRenovationHomeownerFinal(true)
    setChoices(prevState => ({
      ...prevState,
      installing: "Homeowner",
    }))
    reset(homeOwnerRenovationFinalData)
  }

  // Clicked Back

  const backRenovationHomeownerFinal = () => {
    setInstallRenovation(true)
    setRenovationHomeownerFinal(false)
    setHomeOwnerRenovationFinalData(watchAllFields)
    reset({})
    setChoices(prevState => ({
      ...prevState,
      installing: undefined,
    }))
  }

  // Clicked Submit

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm()

  const watchAllFields = watch()

  const onSubmit = async data => {
    setLoading(true)

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }

    const dataWithDate = {
      ...data,
      ReactDatepicker: data?.ReactDatepicker?.toLocaleDateString(
        "en-US",
        options
      ),
    }

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        dataWithDate,
        captcha: captchaValue,
        choices,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.captcha === false) {
          setCaptchaFailed(true)
        }
        if (data.email === true) {
          setNextStage(true)
        } else {
          setNextStage(true)
          SetFailedEmail(true)
        }
      })
      .catch(error => {
        console.error("Error:", error)
        SetFailedEmail(true)
      })

    console.log(choices)
    console.log(data)
  }

  // # 1b clicked Contractor

  const renovationContractor = () => {
    setInstallRenovation(false)
    setRenovationContractorForm(true)
    reset1(renovationContractorFormData)
    setChoices(prevState => ({
      ...prevState,
      installing: "Contractor",
    }))
  }

  // UseForm

  const {
    register: register1,
    watch: watch1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm()

  const watchAllFields1 = watch1()

  // Clicked back

  const backContractorForm = () => {
    setRenovationContractorForm(false)
    setInstallRenovation(true)
    setRenovationContractorFormData(watchAllFields1)
    reset1({})
    setChoices(prevState => ({
      ...prevState,
      installing: undefined,
    }))
  }

  // Clicked next

  const nextContractForm = () => {
    setRenovationContractorForm(false)
    setRenovationContractorFinal(true)
    setRenovationContractorFormData(watchAllFields1)
    reset1({})
    reset2(renovationContractorFinalData)
  }

  // Clicked back

  const backRenovationContractorFinal = () => {
    setRenovationContractorForm(true)
    setRenovationContractorFinal(false)
    reset1(renovationContractorFormData)
    reset2({})
    setRenovationContractorFinalData(watchAllFields2)
  }

  // UseForm

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    reset: reset2,
    control: control2,
    formState: { errors: errors2 },
  } = useForm()

  const watchAllFields2 = watch2()

  // Clicked submit

  const onSubmit2 = async data => {
    setLoading2(true)

    // console.log("data renovation-contractor", data)
    // console.log('choices', choices)
    // console.log('renovationContractorFormData',renovationContractorFormData)

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }

    const dataWithDate = {
      ...data,
      ReactDatepicker: data?.ReactDatepicker?.toLocaleDateString(
        "en-US",
        options
      ),
    }

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        dataWithDate,
        captcha: captchaValue,
        choices,
        contractBuilderData: renovationContractorFormData,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.captcha === false) {
          setCaptchaFailed(true)
        }
        if (data.email === true) {
          setNextStage(true)
        } else {
          setNextStage(true)
          SetFailedEmail(true)
        }
      })
      .catch(error => {
        console.error("Error:", error)
        SetFailedEmail(true)
      })

    // console.log(choices)
    // console.log(data)
  }

  // #1c Clicked Absolute Hardwood

  const renovationAbsolute = () => {
    setRenovationAbsoluteFinal(true)
    setInstallRenovation(false)
    setChoices(prevState => ({
      ...prevState,
      installing: "Absolute Hardwood",
    }))
    reset6(renovationAbsoluteFinalData)
  }

  // Clicked back

  const backRenovationAbsoluteFinal = () => {
    setRenovationAbsoluteFinal(false)
    setInstallRenovation(true)
    setChoices(prevState => ({
      ...prevState,
      installing: undefined,
    }))
    reset6({})
    setRenovationAbsoluteFinalData(watchAllFields6)
  }

  // UseForm

  const {
    register: register6,
    handleSubmit: handleSubmit6,
    watch: watch6,
    reset: reset6,
    control: control6,
    formState: { errors: errors6 },
  } = useForm()

  const watchAllFields6 = watch6()

  // Clicked submit

  const onSubmit6 = async data => {
    setLoading6(true)

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }

    const dataWithDate = {
      ...data,
      ReactDatepicker: data?.ReactDatepicker?.toLocaleDateString(
        "en-US",
        options
      ),
    }

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        dataWithDate,
        captcha: captchaValue,
        choices,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.captcha === false) {
          setCaptchaFailed(true)
        }
        if (data.email === true) {
          setNextStage(true)
        } else {
          setNextStage(true)
          SetFailedEmail(true)
        }
      })
      .catch(error => {
        console.error("Error:", error)
        SetFailedEmail(true)
      })

    console.log(choices)
    console.log(data)
  }

  // Clicked #1 d Not sure yet

  const renovationNotsure = () => {
    setRenovationNotsureFinal(true)
    setInstallRenovation(false)
    setChoices(prevState => ({
      ...prevState,
      installing: "Not sure yet",
    }))
    reset8(renovationNotsureFinalData)
  }

  const backRenovationNotsureFinal = () => {
    setRenovationNotsureFinal(false)
    setInstallRenovation(true)
    setChoices(prevState => ({
      ...prevState,
      installing: undefined,
    }))
    setRenovationNotsureFinalData(watchAllFields8)
    reset8({})
  }

  // UseForm

  const {
    register: register8,
    handleSubmit: handleSubmit8,
    watch: watch8,
    reset: reset8,
    control: control8,
    formState: { errors: errors8 },
  } = useForm()

  const watchAllFields8 = watch8()

  // Clicked submit

  const onSubmit8 = async data => {
    setLoading8(true)

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }

    const dataWithDate = {
      ...data,
      ReactDatepicker: data?.ReactDatepicker?.toLocaleDateString(
        "en-US",
        options
      ),
    }

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        dataWithDate,
        captcha: captchaValue,
        choices,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.captcha === false) {
          setCaptchaFailed(true)
        }
        if (data.email === true) {
          setNextStage(true)
        } else {
          setNextStage(true)
          SetFailedEmail(true)
        }
      })
      .catch(error => {
        console.error("Error:", error)
        SetFailedEmail(true)
      })

    console.log(choices)
    console.log(data)
  }

  //----------------------------------------------------------------------------------------------------

  // # 2 Clicked construction
  //----------------------------------------------------------------------------------------------------

  const construction = () => {
    setType(false)
    setNewConstruction(true)
    setChoices({
      project: "New Construction",
    })
  }

  // Clicked back

  const backInstallConstruction = () => {
    setType(true)
    setNewConstruction(false)
    setChoices({})
  }

  // Clicked Homeowner

  const constructionHomeowner = () => {
    setConstructionHomeownerFinal(true)
    setNewConstruction(false)
    setChoices(prevState => ({
      ...prevState,
      installing: "Homeowner",
    }))
    reset3(homeOwnerConstructionFinalData)
  }

  // Clicked Back

  const backConstructionHomeownerFinal = () => {
    setConstructionHomeownerFinal(false)
    setNewConstruction(true)
    setChoices(prevState => ({
      ...prevState,
      installing: undefined,
    }))
    reset3({})
    setHomeOwnerConstructionFinalData(watchAllFields3)
  }

  // UseForm

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    watch: watch3,
    reset: reset3,
    control: control3,
    formState: { errors: errors3 },
  } = useForm()

  const watchAllFields3 = watch3()

  // Clicked submit

  const onSubmit3 = async data => {
    setLoading3(true)

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }

    const dataWithDate = {
      ...data,
      ReactDatepicker: data?.ReactDatepicker?.toLocaleDateString(
        "en-US",
        options
      ),
    }

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        dataWithDate,
        captcha: captchaValue,
        choices,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.captcha === false) {
          setCaptchaFailed(true)
        }
        if (data.email === true) {
          setNextStage(true)
        } else {
          setNextStage(true)
          SetFailedEmail(true)
        }
      })
      .catch(error => {
        console.error("Error:", error)
        SetFailedEmail(true)
      })

    console.log(choices)
    console.log(data)
  }

  // Clicked #2 b Contractor

  const constructionContractor = () => {
    setNewConstruction(false)
    setConstructionContractorForm(true)
    setChoices(prevState => ({
      ...prevState,
      installing: "Contractor",
    }))
    reset4(constructionContractorFormData)
  }

  const backContractorFormB = () => {
    setNewConstruction(true)
    setConstructionContractorForm(false)
    setChoices(prevState => ({
      ...prevState,
      installing: undefined,
    }))
    reset4({})
    setConstructionContractorFormData(watchAllFields4)
  }

  // Useform

  const {
    register: register4,
    watch: watch4,
    reset: reset4,
    formState: { errors: errors4 },
  } = useForm()

  const watchAllFields4 = watch4()

  // Clicked Next

  const nextContractFormB = () => {
    setConstructionContractorFinal(true)
    setConstructionContractorForm(false)
    reset5(contractorConstructionFinalData)
    setConstructionContractorFormData(watchAllFields4)
  }

  // Clicked Back

  const backConstructionContractorFinal = () => {
    setConstructionContractorFinal(false)
    setConstructionContractorForm(true)
    reset5({})
    reset4(constructionContractorFormData)
    setContractorConstructionFinalData(watchAllFields5)
  }

  // UseForm

  const {
    register: register5,
    handleSubmit: handleSubmit5,
    watch: watch5,
    reset: reset5,
    control: control5,
    formState: { errors: errors5 },
  } = useForm()

  const watchAllFields5 = watch5()

  // Clicked submit

  const onSubmit5 = async data => {
    setLoading5(true)

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }

    const dataWithDate = {
      ...data,
      ReactDatepicker: data?.ReactDatepicker?.toLocaleDateString(
        "en-US",
        options
      ),
    }

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        dataWithDate,
        captcha: captchaValue,
        choices,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.captcha === false) {
          setCaptchaFailed(true)
        }
        if (data.email === true) {
          setNextStage(true)
        } else {
          setNextStage(true)
          SetFailedEmail(true)
        }
      })
      .catch(error => {
        console.error("Error:", error)
        SetFailedEmail(true)
      })

    console.log(choices)
    console.log(data)
  }

  // Clicked #2 c Absolute Hardwood

  const constructionAbsolute = () => {
    setConstructionAbsoluteFinal(true)
    setNewConstruction(false)
    reset7(contractorAbsoluteFinalData)
    setChoices(prevState => ({
      ...prevState,
      installing: "Absolute Hardwood",
    }))
  }

  const backConstructionAbsoluteFinal = () => {
    setConstructionAbsoluteFinal(false)
    setNewConstruction(true)
    reset7({})
    setContractorAbsoluteFinalData(watchAllFields7)
    setChoices(prevState => ({
      ...prevState,
      installing: undefined,
    }))
  }

  // UseForm

  const {
    register: register7,
    handleSubmit: handleSubmit7,
    watch: watch7,
    reset: reset7,
    control: control7,
    formState: { errors: errors7 },
  } = useForm()

  const watchAllFields7 = watch7()

  // Clicked submit

  const onSubmit7 = async data => {
    setLoading7(true)

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }

    const dataWithDate = {
      ...data,
      ReactDatepicker: data?.ReactDatepicker?.toLocaleDateString(
        "en-US",
        options
      ),
    }

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        dataWithDate,
        captcha: captchaValue,
        choices,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.captcha === false) {
          setCaptchaFailed(true)
        }
        if (data.email === true) {
          setNextStage(true)
        } else {
          setNextStage(true)
          SetFailedEmail(true)
        }
      })
      .catch(error => {
        console.error("Error:", error)
        SetFailedEmail(true)
      })

    console.log(choices)
    console.log(data)
  }

  // Click #2 2d Not sure yet

  const constructionNotsure = () => {
    setConstructionNotSureFinal(true)
    setNewConstruction(false)
    setChoices(prevState => ({
      ...prevState,
      installing: "Not sure yet",
    }))
    reset9(contractorNotsureFinalData)
  }

  const backConstructionNotsureFinal = () => {
    setConstructionNotSureFinal(false)
    setNewConstruction(true)
    setChoices(prevState => ({
      ...prevState,
      installing: undefined,
    }))
    reset9({})
    setContractorNotsureFinalData(watchAllFields9)
  }

  // UseForm

  const {
    register: register9,
    handleSubmit: handleSubmit9,
    watch: watch9,
    reset: reset9,
    control: control9,
    formState: { errors: errors9 },
  } = useForm()

  const watchAllFields9 = watch9()

  // Clicked submit

  const onSubmit9 = async data => {
    setLoading9(true)

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }

    const dataWithDate = {
      ...data,
      ReactDatepicker: data?.ReactDatepicker?.toLocaleDateString(
        "en-US",
        options
      ),
    }

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        dataWithDate,
        captcha: captchaValue,
        choices,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.captcha === false) {
          setCaptchaFailed(true)
        }
        if (data.email === true) {
          setNextStage(true)
        } else {
          setNextStage(true)
          SetFailedEmail(true)
        }
      })
      .catch(error => {
        console.error("Error:", error)
        SetFailedEmail(true)
      })

    console.log(choices)
    console.log(data)
  }

  //----------------------------------------------------------------------------------------------------

  // Captcha
  //----------------------------------------------------------------------------------------------------

  function onChange(value) {
    setSubmitButton(false)
    console.log("Captcha value:", value)
    setCaptchaValue(value)
  }

  function error(value) {
    console.log(value)
  }

  function retry() {
    setLoading(false)
  }
  function retry2() {
    setLoading2(false)
  }
  function retry3() {
    setLoading3(false)
  }
  function retry5() {
    setLoading5(false)
  }
  function retry6() {
    setLoading6(false)
  }
  function retry7() {
    setLoading7(false)
  }
  function retry8() {
    setLoading8(false)
  }
  function retry9() {
    setLoading9(false)
  }

  return (
    <Wrapper>
      {/* <pre>{JSON.stringify(choices, null, 2)}</pre> */}
      {type && (
        <Type>
          <h2>What type of project are you tackling?</h2>
          <h3>Click one below</h3>
          <ChoiceGrid>
            <div>
              <Choice onClick={renovation}>Renovation</Choice>
            </div>
            <div>
              <Choice onClick={construction}>New Construction</Choice>
            </div>
          </ChoiceGrid>
        </Type>
      )}
      {installRenovation && (
        <InstallRenovation>
          <h2>Who is installing the floor?</h2>
          <h3>Choose one below</h3>
          <ChoiceGridFour>
            <div>
              <Choice onClick={renovationHomeowner}>Homeowner</Choice>
            </div>
            <div>
              <Choice onClick={renovationContractor}>
                Builder / Contractor
              </Choice>
            </div>
            <div>
              <Choice onClick={renovationAbsolute}>Absolute Hardwood</Choice>
            </div>
            <div>
              <Choice onClick={renovationNotsure}>Not sure yet</Choice>
            </div>
          </ChoiceGridFour>
          <Back onClick={backInstallRenovation}>Back</Back>
        </InstallRenovation>
      )}
      {renovationContractorForm && (
        <>
          <FormContractBuilderAStyle>
            <FormA
              registerFunction={register1}
              path="Renovation -> Contractor"
            />
          </FormContractBuilderAStyle>
          <NavGrid>
            <Back onClick={backContractorForm}>Back</Back>
            <Next onClick={nextContractForm}>Next</Next>
          </NavGrid>
        </>
      )}
      {renovationHomeownerFinal && (
        <>
          {!loading ? (
            <>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormFinal
                  path="Renovation -> Homeowner"
                  controlFunction={control}
                  registerFunction={register}
                  pathData={choices}
                />
                <div className="gridMe">
                  <ReCAPTCHA
                    className="captchaStyle"
                    sitekey="6LcCy_kUAAAAAA_zgBIcQxaiX9qcluqRdBUEyz8C"
                    onChange={onChange}
                    onErrored={error}
                  />
                  <input
                    type="submit"
                    className={submitButton ? "disabled" : "enabled"}
                    disabled={submitButton ? "disabled" : ""}
                  />
                </div>
              </Form>
              <Back onClick={backRenovationHomeownerFinal}>Back</Back>
            </>
          ) : (
            <StatusComp
              captchaFailed={captchaFailed}
              nextStage={nextStage}
              failedEmail={failedEmail}
              retry={retry}
            />
          )}
        </>
      )}
      {renovationContractorFinal && (
        <>
          {!loading2 ? (
            <>
              <Form onSubmit={handleSubmit2(onSubmit2)}>
                <FormFinal
                  path="Renovation -> Contractor"
                  controlFunction={control2}
                  registerFunction={register2}
                  pathData={choices}
                />
                <div className="gridMe">
                  <ReCAPTCHA
                    className="captchaStyle"
                    sitekey="6LcCy_kUAAAAAA_zgBIcQxaiX9qcluqRdBUEyz8C"
                    onChange={onChange}
                    onErrored={error}
                  />
                  <input
                    type="submit"
                    className={submitButton ? "disabled" : "enabled"}
                    disabled={submitButton ? "disabled" : ""}
                  />
                </div>
              </Form>
              <Back onClick={backRenovationContractorFinal}>Back</Back>
            </>
          ) : (
            <StatusComp
              captchaFailed={captchaFailed}
              nextStage={nextStage}
              failedEmail={failedEmail}
              retry={retry2}
            />
          )}
        </>
      )}
      {renovationAbsoluteFinal && (
        <>
          {!loading6 ? (
            <>
              <Form onSubmit={handleSubmit6(onSubmit6)}>
                <FormFinal
                  path="Renovation -> Homeowner"
                  controlFunction={control6}
                  registerFunction={register6}
                  pathData={choices}
                />
                <div className="gridMe">
                  <ReCAPTCHA
                    className="captchaStyle"
                    sitekey="6LcCy_kUAAAAAA_zgBIcQxaiX9qcluqRdBUEyz8C"
                    onChange={onChange}
                    onErrored={error}
                  />
                  <input
                    type="submit"
                    className={submitButton ? "disabled" : "enabled"}
                    disabled={submitButton ? "disabled" : ""}
                  />
                </div>
              </Form>
              <Back onClick={backRenovationAbsoluteFinal}>Back</Back>
            </>
          ) : (
            <StatusComp
              captchaFailed={captchaFailed}
              nextStage={nextStage}
              failedEmail={failedEmail}
              retry={retry6}
            />
          )}
        </>
      )}
      {renovationNotsureFinal && (
        <>
          {!loading8 ? (
            <>
              <Form onSubmit={handleSubmit8(onSubmit8)}>
                <FormFinal
                  path="Renovation -> Homeowner"
                  controlFunction={control8}
                  registerFunction={register8}
                  pathData={choices}
                />
                <div className="gridMe">
                  <ReCAPTCHA
                    className="captchaStyle"
                    sitekey="6LcCy_kUAAAAAA_zgBIcQxaiX9qcluqRdBUEyz8C"
                    onChange={onChange}
                    onErrored={error}
                  />
                  <input
                    type="submit"
                    className={submitButton ? "disabled" : "enabled"}
                    disabled={submitButton ? "disabled" : ""}
                  />
                </div>
              </Form>
              <Back onClick={backRenovationNotsureFinal}>Back</Back>
            </>
          ) : (
            <StatusComp
              captchaFailed={captchaFailed}
              nextStage={nextStage}
              failedEmail={failedEmail}
              retry={retry8}
            />
          )}
        </>
      )}
      {newConstruction && (
        <NewConstruction>
          <h2>Who is installing the floor?</h2>
          <h3>Choose one below</h3>
          <ChoiceGridFour>
            <div>
              <Choice onClick={constructionHomeowner}>Homeowner</Choice>
            </div>
            <div>
              <Choice onClick={constructionContractor}>
                Builder / Contractor
              </Choice>
            </div>
            <div>
              <Choice onClick={constructionAbsolute}>Absolute Hardwood</Choice>
            </div>
            <div>
              <Choice onClick={constructionNotsure}>Not sure yet</Choice>
            </div>
          </ChoiceGridFour>
          <Back onClick={backInstallConstruction}>Back</Back>
        </NewConstruction>
      )}
      {constructionContractorForm && (
        <>
          <FormContractBuilderAStyle>
            <FormA
              registerFunction={register4}
              path="New Construction -> Contractor"
            />
          </FormContractBuilderAStyle>
          <NavGrid>
            <Back onClick={backContractorFormB}>Back</Back>
            <Next onClick={nextContractFormB}>Next</Next>
          </NavGrid>
        </>
      )}
      {constructionContractorFinal && (
        <>
          {!loading5 ? (
            <>
              <Form onSubmit={handleSubmit5(onSubmit5)}>
                <FormFinal
                  path="New Construction -> Contractor"
                  controlFunction={control5}
                  registerFunction={register5}
                  pathData={choices}
                />
                <div className="gridMe">
                  <ReCAPTCHA
                    className="captchaStyle"
                    sitekey="6LcCy_kUAAAAAA_zgBIcQxaiX9qcluqRdBUEyz8C"
                    onChange={onChange}
                    onErrored={error}
                  />
                  <input
                    type="submit"
                    className={submitButton ? "disabled" : "enabled"}
                    disabled={submitButton ? "disabled" : ""}
                  />
                </div>
              </Form>
              <Back onClick={backConstructionContractorFinal}>Back</Back>
            </>
          ) : (
            <StatusComp
              captchaFailed={captchaFailed}
              nextStage={nextStage}
              failedEmail={failedEmail}
              retry={retry5}
            />
          )}
        </>
      )}
      {constructionHomeownerFinal && (
        <>
          {!loading3 ? (
            <>
              <Form onSubmit={handleSubmit3(onSubmit3)}>
                <FormFinal
                  path="New Construction -> Homeowner"
                  controlFunction={control3}
                  registerFunction={register3}
                  pathData={choices}
                />
                <div className="gridMe">
                  <ReCAPTCHA
                    className="captchaStyle"
                    sitekey="6LcCy_kUAAAAAA_zgBIcQxaiX9qcluqRdBUEyz8C"
                    onChange={onChange}
                    onErrored={error}
                  />
                  <input
                    type="submit"
                    className={submitButton ? "disabled" : "enabled"}
                    disabled={submitButton ? "disabled" : ""}
                  />
                </div>
              </Form>
              <Back onClick={backConstructionHomeownerFinal}>Back</Back>
            </>
          ) : (
            <StatusComp
              captchaFailed={captchaFailed}
              nextStage={nextStage}
              failedEmail={failedEmail}
              retry={retry3}
            />
          )}
        </>
      )}
      {constructionAbsoluteFinal && (
        <>
          {!loading7 ? (
            <>
              {/* <pre>{JSON.stringify(choices, null, 2)}</pre> */}
              <Form onSubmit={handleSubmit7(onSubmit7)}>
                <FormFinal
                  path="New Construction -> Absolute Hadwood"
                  controlFunction={control7}
                  registerFunction={register7}
                  pathData={choices}
                />
                <div className="gridMe">
                  <ReCAPTCHA
                    className="captchaStyle"
                    sitekey="6LcCy_kUAAAAAA_zgBIcQxaiX9qcluqRdBUEyz8C"
                    onChange={onChange}
                    onErrored={error}
                  />
                  <input
                    type="submit"
                    className={submitButton ? "disabled" : "enabled"}
                    disabled={submitButton ? "disabled" : ""}
                  />
                </div>
              </Form>
              <Back onClick={backConstructionAbsoluteFinal}>Back</Back>
            </>
          ) : (
            <StatusComp
              captchaFailed={captchaFailed}
              nextStage={nextStage}
              failedEmail={failedEmail}
              retry={retry7}
            />
          )}
        </>
      )}
      {constructionNotSureFinal && (
        <>
          {!loading9 ? (
            <>
              <Form onSubmit={handleSubmit9(onSubmit9)}>
                <FormFinal
                  path="New Construction -> Homeowner"
                  controlFunction={control9}
                  registerFunction={register9}
                  pathData={choices}
                />
                <div className="gridMe">
                  <ReCAPTCHA
                    className="captchaStyle"
                    sitekey="6LcCy_kUAAAAAA_zgBIcQxaiX9qcluqRdBUEyz8C"
                    onChange={onChange}
                    onErrored={error}
                  />
                  <input
                    type="submit"
                    className={submitButton ? "disabled" : "enabled"}
                    disabled={submitButton ? "disabled" : ""}
                  />
                </div>
              </Form>
              <Back onClick={backConstructionNotsureFinal}>Back</Back>
            </>
          ) : (
            <StatusComp
              captchaFailed={captchaFailed}
              nextStage={nextStage}
              failedEmail={failedEmail}
              retry={retry9}
            />
          )}
        </>
      )}
      <Choices>
        {choices.project && (
          <p>
            What type of project are you tackling?:{" "}
            <span>{choices.project}</span>
          </p>
        )}
        {choices.installing && (
          <p>
            Who is installing the floor?: <span>{choices.installing}</span>
          </p>
        )}
        {choices.whoIsPurchasing && (
          <p>
            Who is purchasing the Flooring Directly from Absolute Hardwood?:{" "}
            <span>{choices.whoIsPurchasing}</span>
          </p>
        )}
      </Choices>
    </Wrapper>
  )
}

export default Quote

import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import StarRatingComponent from "react-star-rating-component"
// import avatar1 from "../images/avatar.png"

const ReviewsWrap = styled.section`
  max-width: 90%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 50px;
  text-align: center;
  margin: 50px auto;
  @media (max-width: 1281px) {
    width: 950px;
    grid-template-columns: repeat(3, 250px);
  }
  @media (max-width: 1130px) {
    width: 750px;
    grid-template-columns: repeat(3, 200px);
  }
  @media (max-width: 900px) {
    width: 650px;
    grid-template-columns: repeat(2, 250px);
  }
  @media (max-width: 700px) {
    width: 75%;
    grid-template-columns: 1fr;
  }
  img {
    width: 50px;
    margin: 25px auto;
    display: block;
    border-radius: 50%;
  }
  .review {
    box-shadow: -2px 5px 10px grey;
    padding: 30px;
    border-radius: 20px;
    overflow: scroll;
    height: 100%;
    &:hover {
      box-shadow: 0px 5px 5px grey;
      cursor: pointer;
    }
    &.full {
      //position: absolute;
      background: #ffffff;
      width: 600px;

      left: 50%;
      //margin-left: -300px;
      @media (max-width: 650px) {
        width: 320px;
      }
    }
  }
  .fullBg {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #808080bf;
    display: grid;
    align-items: center;
    justify-items: center;
    left: 0;
    top: 0;
    z-index: 1000;
  }
`

const Header = styled.div`
  h1 {
    color: #fff;
  }
  p {
    color: #000;
  }
`

const CustomReviews = ({ authorName, email, text }) => {
  const [showFull, setShowFull] = useState(false)

  const popOpen = () => {
    setShowFull(true)
  }
  const popClose = () => {
    setShowFull(false)
  }

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={popOpen}
        onClick={popOpen}
        className="review"
      >
        <h3>{authorName}</h3>
        <img
          alt="avatar"
          src={`https://unavatar.now.sh/${email}?fallback=https://fallbacks.s3.us-east-2.amazonaws.com/avatar.png`}
        />
        <StarRatingComponent
          name="rate1"
          starCount={5}
          value={5}
          editable={false}
        />
        <p>{`${text.substring(0, 100)} ...`}</p>
        <button onClick={popOpen}>read review</button>
      </div>
      {showFull && (
        <div
          role="button"
          onClick={popClose}
          tabIndex={0}
          onKeyDown={popClose}
          className="fullBg"
        >
          <div
            role="button"
            onClick={popClose}
            tabIndex={0}
            onKeyDown={popClose}
            className="review full"
          >
            <h3>{authorName}</h3>
            <img
              alt="avatar"
              src={`https://unavatar.now.sh/${email}?fallback=https://fallbacks.s3.us-east-2.amazonaws.com/avatar.png`}
            />
            <StarRatingComponent
              name="rate1"
              starCount={5}
              value={5}
              editable={false}
            />
            <p>{text}</p>
            <button onClick={popClose}>close</button>
          </div>
        </div>
      )}
    </>
  )
}

const GoogleReviews = ({ authorName, profilePhotoUrl, text }) => {
  const [showFull, setShowFull] = useState(false)

  const popOpen = () => {
    setShowFull(true)
  }
  const popClose = () => {
    setShowFull(false)
  }

  return (
    <>
      <div
        onClick={popOpen}
        role="button"
        tabIndex={0}
        onKeyDown={popOpen}
        className="review"
      >
        <h3>{authorName}</h3>
        <img alt="avatar" src={profilePhotoUrl} />
        <StarRatingComponent
          name="rate1"
          starCount={5}
          value={5}
          editable={false}
        />
        <h4>Review from Google</h4>

        <p>{`${text.substring(0, 100)} ...`}</p>
        <button onClick={popOpen}>read review</button>
      </div>
      {showFull && (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={popClose}
          onClick={popClose}
          className="fullBg"
        >
          <div
            onClick={popClose}
            role="button"
            tabIndex={0}
            onKeyDown={popClose}
            className="review full"
          >
            <h3>{authorName}</h3>
            <img alt="avatar" src={profilePhotoUrl} />
            <StarRatingComponent
              name="rate1"
              starCount={5}
              value={5}
              editable={false}
            />
            <h4>Review from Google</h4>

            <p>{text}</p>
            <button onClick={popClose}>close</button>
          </div>
        </div>
      )}
    </>
  )
}

const Reviews = () => {
  return (
    <StaticQuery
      query={graphql`
        query ReviewsQuery {
          allGooglePlacesReview {
            __typename
            edges {
              node {
                __typename
                author_name
                profile_photo_url
                rating
                text
              }
            }
          }
          sanityTestimonials {
            subTitle
            title
            buttonTitle
            testimonialObject {
              __typename
              text: content
              email
              show
              author_name: name
              _key
            }
          }
        }
      `}
      render={data => {
        // console.log(data.sanityTestimonials)
        // console.log(data.allGooglePlacesReview)

        let testimonialArray = [
          ...data.sanityTestimonials.testimonialObject,
          ...data.allGooglePlacesReview.edges,
        ]

        let amountFalse = []

        testimonialArray.map((data, i) => {
          return amountFalse.push(data.show)
          // if (i < 5 + amountFalseCount) {
          //   if (data.show) {
          //     console.log(data.author_name)
          //   } else {
          //     if (data.node) {
          //       console.log(data.node.author_name)
          //     }
          //   }
          // }
        })

        const amountFalseCount = amountFalse.filter(
          item => item === false || item === null
        ).length

        return (
          <>
            <Header className="header">
              <h1>{data.sanityTestimonials.title}</h1>
              <p>{data.sanityTestimonials.subTitle}</p>
              <Link className="view-all" to="/testimonials">
                {data.sanityTestimonials.buttonTitle}
              </Link>
            </Header>
            <ReviewsWrap>
              {testimonialArray.map((data, i) => {
                return (
                  <React.Fragment key={i}>
                    {i < 5 + amountFalseCount && (
                      <>
                        {data.show ? (
                          <>
                            <CustomReviews
                              authorName={data.author_name}
                              email={data.email}
                              text={data.text}
                            />
                          </>
                        ) : (
                          <>
                            {data.node && (
                              <GoogleReviews
                                authorName={data.node.author_name}
                                profilePhotoUrl={data.node.profile_photo_url}
                                text={data.node.text}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )
              })}
            </ReviewsWrap>
          </>
        )
      }}
    />
  )
}

export default Reviews

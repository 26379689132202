import React, { useEffect, useRef } from "react"
import BlockContent from "@sanity/block-content-to-react"
import GalleryModal from "../components/GalleryModal"
import ReactTooltip from "react-tooltip"
import { Carousel } from "react-responsive-carousel"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
}

const ImageSingleDesign = ({ detailEntry, i }) => {
  const serializers = {
    types: {
      justContent: props => <>{props.node.description}</>,
    },
  }

  function closeModal() {
    setIsOpen(false)
  }

  const [modalIsOpen, setIsOpen] = React.useState(false)
  const modalRef = useRef(null);  // Ref for the modal
  const [isLoading, setIsLoading] = React.useState(true);

  // Click outside modal handler
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);  // Adding modalRef here ensures the effect updates if ref changes

  function handleImageLoaded() {
    setIsLoading(false);
  }

  function openModal() {
    setIsLoading(true);  // Reset loading state every time modal opens
    setIsOpen(true)
  }

  return (
    <div>
      {detailEntry.title && <h2>{detailEntry.title}</h2>}
      {detailEntry.image && detailEntry.image.asset && (
        <>
          <a onClick={()=> openModal()} data-tip data-for="imageGallery">
            <GatsbyImage
              image={detailEntry.image.asset.gatsbyImageData}
              
              // alt={
              //   detailEntry.floorImage.asset.alt
              //     ? detailEntry.floorImage.asset.alt
              //     : ""
              // }
            />
          </a>
          <ReactTooltip id="imageGallery" type="dark">
            <span>Click to view Gallery</span>
          </ReactTooltip>
        </>
      )}


      {modalIsOpen && detailEntry.imageGallery.length > 0 && (
        <GalleryModStyles>
          <>
            <CWStyles ref={modalRef}>
              <Carousel
                infiniteLoop={true}
                showIndicators={false}
                showThumbs={false}
                swipeable={false}
              >
                {detailEntry.imageGallery?.map((item, i) => (
                  <div>
                    <p>
                      <button onClick={closeModal}>close</button>
                    </p>
                    <img src={item.asset.url} onLoad={handleImageLoaded} />
                    {isLoading && (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <Loader></Loader>  {/* This can be replaced with a spinner component */}
                      </div>
                    )}
                  </div>
                ))}
              </Carousel>
            </CWStyles>
          </>
        </GalleryModStyles>
      )}

      {/* {detailEntry.imageGallery && (
        <GalleryModal
          data={detailEntry.imageGallery}
          style={customStyles}
          contentLabel="Example Modal"
          isOpen={isOpen}
          setIsOpenGo={setIsOpenGo}
        />
      )} */}

      {detailEntry.description && (
        <BlockContent
          blocks={detailEntry.description.explanation}
          serializers={serializers}
        />
      )}
      {/* {i % 3 === 2 && <div className="full"></div>} */}
    </div>
  )
}

export default ImageSingleDesign

const GalleryModStyles = styled.div`
  position: fixed;
  background: #acacacd4;
  z-index: 9999999999;
  width: 100%;
  height: 100vh;
  margin-left: -50%;
  left: 50%;
  top: 0px;
  padding: 0 !important;
`

const CWStyles = styled.div`
  button {
    background: #000;
    color: #fff;
    padding: 25px;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    width: 100px;
    text-align: center;
  }
  margin: 0 auto;
  padding: 0 !important;
  display: block;
  position: relative;
  width: 65% !important;
  @media (max-width: 1400px) {
    width: 80% !important;
  }
  @media (max-width: 1000px) {
    width: 90% !important;
  }
  @media (max-width: 750px) {
    width: 100% !important;
  }
  @media (max-width: 750px) {
    div {
      padding: 0 !important;
    }
  }
  .carousel .slide img {
    height: auto !important;
    max-height:700px;
  }
  div.carousel-root {
    padding: 0 !important;
  }
  .carousel .control-prev.control-arrow {
    left: 0;
    height: 100px;
    top: 400px !important;
    position: absolute;
    width: 100px;
    @media (max-width: 750px) {
      top: 200px !important;
    }
    @media (max-width: 450px) {
      top: 100px !important;
    }
  }

  .carousel .control-next.control-arrow {
    right: 0;
    height: 100px;
    top: 400px !important;
    position: absolute;
    width: 100px;
    @media (max-width: 750px) {
      top: 200px !important;
    }
    @media (max-width: 450px) {
      top: 100px !important;
    }
  }
  .carousel .control-arrow:before,
  .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 20px solid #ffffff00;
    border-bottom: 20px solid transparent;
    content: "";
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 40px solid #fff;
  }
  .carousel .control-next.control-arrow:before {
    border-left: 40px solid #fff;
  }
`

const Loader = styled.div`
  height: 30px;
  aspect-ratio: 6;
  --c: #0000 64%,#000 66% 98%,#0000 101%;
  background:
    radial-gradient(35% 146% at 50% 159%,var(--c)) 0 0,
    radial-gradient(35% 146% at 50% -59%,var(--c)) 25% 100%;
  background-size: calc(100%/3) 50%;
  background-repeat: repeat-x;
  clip-path: inset(0 50%);
  animation: l3 1s infinite linear;

@keyframes l3{
  90%,to {clip-path: inset(0)}
}
`

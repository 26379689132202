import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { StaticQuery, graphql } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha"
import tw, { styled } from "twin.macro"

const Form = styled.form`
  margin: 50px auto 100px 0;
  width: 75%;
  @media (max-width: 650px) {
    width: 100%;
  }
  label,
  input {
    ${tw`block w-full my-8 text-base`}
  }
  input {
    ${tw`border-b-2 border-black p-1`}
    &:focus {
      outline: none;
    }
  }
  textarea {
    ${tw`w-full h-20 border-2 border-black p-1`}
    resize: none;
    font-size: 1rem;
    &:focus {
      outline: none;
    }
  }
  .captchaStyle div {
    position: relative;
    height: 75px;
    margin: 30px 0;
  }
  .captchaStyle div div {
    transform: scale(0.85);
    width: 300px;
    height: 78px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    margin-left: -150px;
    margin-top: 0px;
    top: 0;
    @media (min-width: 768px) {
      transform: scale(1);
    }
  }
  input[type="submit"] {
    ${tw`w-1/2 m-auto`}
    border: none;
    border-radius: 30px;
    &.disabled {
      background: lightgrey;
      color: #000;
      &:hover {
        cursor: not-allowed;
      }
    }
    background: #000;
    color: #fff;
    &:hover {
      cursor: pointer;
    }
  }
`

const Status = styled.section`
  h1,
  h2,
  h3 {
    text-align: center;
  }
`

const ContactForm = () => {
  const { register, handleSubmit, watch, errors } = useForm()

  const isBrowser = typeof window !== `undefined`
  let location
  if (isBrowser) {
    location = window.location.hostname
  }
  let fetchUrl
  if (location === "localhost") {
    fetchUrl = `http://localhost:8888/.netlify/functions/contact`
  } else if (
    location === "website-gatsby-4821585328.gtsb.io" ||
    location === "dev.absolutehardwood.ca"
  ) {
    fetchUrl = `https://absolute-hardwood-dev-static.netlify.app/.netlify/functions/contact`
  } else {
    fetchUrl = "/.netlify/functions/contact"
  }

  let formCustomMessageArr = []

  const onSubmit = async data => {
    setLoading(true)

    console.log(captchaValue, "captcha value final")
    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        data,
        captcha: captchaValue,
        formCustomMessageArr,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.captcha === false) {
          setCaptchaFailed(true)
        }
        if (data.email === true) {
          setNextStage(true)
        } else {
          setNextStage(true)
          SetFailedEmail(true)
        }
      })
      .catch(error => {
        console.error("Error:", error)
        SetFailedEmail(true)
      })
  }
  const [submitButton, setSubmitButton] = useState(true)
  const [loading, setLoading] = useState(false)
  const [captchaFailed, setCaptchaFailed] = useState(false)
  const [captchaValue, setCaptchaValue] = useState(null)
  const [nextStage, setNextStage] = useState(false)
  const [failedEmail, SetFailedEmail] = useState(false)

  function onChange(value) {
    setSubmitButton(false)
    console.log("Captcha value:", value)
    setCaptchaValue(value)
  }

  function error(value) {
    //alert(value)
  }

  function retry() {
    setLoading(false)
  }

  return (
    <StaticQuery
      query={graphql`
        query ContactFormQuery {
          sanityContactFormMessage {
            title
            subject
            message
          }
        }
      `}
      render={data => {
        formCustomMessageArr.push(data.sanityContactFormMessage.title)
        formCustomMessageArr.push(data.sanityContactFormMessage.subject)
        formCustomMessageArr.push(data.sanityContactFormMessage.message)

        return (
          <>
            {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
            {!loading ? (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <h2>Contact Form</h2>
                  <label htmlFor="name">Name</label>
                  <input name="name" ref={register({ required: true })} />
                  {errors.name && <span>This field is required</span>}
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    ref={register({ required: true })}
                  />
                  {errors.email && <span>This field is required</span>}
                </div>
                <div>
                  <label htmlFor="message">Message</label>
                  <textarea name="message" ref={register({ required: true })} />
                  {errors.message && <span>This field is required</span>}
                </div>
                <ReCAPTCHA
                  className="captchaStyle"
                  sitekey="6LcCy_kUAAAAAA_zgBIcQxaiX9qcluqRdBUEyz8C"
                  onChange={onChange}
                  onErrored={error}
                />
                <input
                  type="submit"
                  className={submitButton ? "disabled" : ""}
                  disabled={submitButton ? "disabled" : ""}
                />
              </Form>
            ) : (
              <Status>
                {captchaFailed ? (
                  <>
                    <p>Captcha Verification Failed</p>
                    <button onClick={retry}>retry</button>
                  </>
                ) : (
                  <>
                    {!nextStage ? (
                      <div class="loader">Loading...</div>
                    ) : (
                      <>
                        {failedEmail ? (
                          <>
                            <h1>Email Failed Contact us here </h1>
                            <h2>
                              <a href="mailto:salesteam@absolutehardwood.ca">
                                salesteam@absolutehardwood.ca
                              </a>
                            </h2>
                            <h3>
                              Or Retry here{" "}
                              <button onClick={retry}>retry</button>
                            </h3>
                          </>
                        ) : (
                          <>
                            <h1>Succesfully submitted</h1>
                            <svg
                              class="checkmark"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 52 52"
                            >
                              <circle
                                class="checkmark__circle"
                                cx="26"
                                cy="26"
                                r="25"
                                fill="none"
                              />
                              <path
                                class="checkmark__check"
                                fill="none"
                                d="M14.1 27.2l7.1 7.2 16.7-16.8"
                              />
                            </svg>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Status>
            )}
          </>
        )
      }}
    />
  )
}

export default ContactForm

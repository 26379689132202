import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import tw, { styled } from "twin.macro"
import ContactForm from "./contactForm"

const Block = styled.article`
  ${tw`grid grid-cols-1 lg:grid-cols-2 w-4/5 pt-10 lg:pt-20 m-auto gap-10`}
  p {
    ${tw`m-0`}
  }
  a {
    text-decoration: underline;
  }
`

const BlockSecond = styled.div`
  ${tw`grid grid-cols-1 lg:grid-cols-2 w-4/5 pt-10 lg:pt-20 m-auto gap-10`}
  @media(max-width:650px) {
    margin-bottom: 50px;
  }
  .google-map {
    height: 550px;
  }
`

const Header = styled.div`
  h1 {
    color: #fff;
  }
  p {
    color: #000;
  }
`

const Contact = () => {
  return (
    <StaticQuery
      query={graphql`
        query ContactQuery {
          sanityContact {
            title
            subTitle
            warrantyTitle
            estimateTitle
            _rawAddress(resolveReferences: { maxDepth: 10 })
            _rawEstimateDesc(resolveReferences: { maxDepth: 10 })
            _rawWarrantyDesc(resolveReferences: { maxDepth: 10 })
            _rawHours(resolveReferences: { maxDepth: 10 })
            addressTitle
            hoursTitle
          }
        }
      `}
      render={data => (
        <>
          <Header className="header">
            <h1>{data.sanityContact.title}</h1>
            <p>{data.sanityContact.subTitle}</p>
          </Header>
          <Block>
            <div>
              <h2>{data.sanityContact.addressTitle}</h2>
              <p>
                {data.sanityContact._rawAddress.streetNo} -{" "}
                {data.sanityContact._rawAddress.street}
              </p>
              <p>{data.sanityContact._rawAddress.city}</p>
              <p>{data.sanityContact._rawAddress.postalCode}</p>
              {/* <a href={`tel:${data.sanityContact._rawAddress.phone}`}>
                {data.sanityContact._rawAddress.phone}
              </a> */}
              {/* <p>{data.sanityContact._rawAddress.email}</p> */}
            </div>
            <div>
              <h2>{data.sanityContact.hoursTitle}</h2>
              <BlockContent blocks={data.sanityContact._rawHours.explanation} />
            </div>
            <div>
              <h2>{data.sanityContact.estimateTitle}</h2>
              <BlockContent
                blocks={data.sanityContact._rawEstimateDesc.explanation}
              />
            </div>
            <div>
              <h2>{data.sanityContact.warrantyTitle}</h2>
              <BlockContent
                blocks={data.sanityContact._rawWarrantyDesc.explanation}
              />
            </div>
          </Block>
          <BlockSecond>
            <ContactForm />
            <div className="google-map">
              <iframe
                width="100%"
                height="100%"
                loading="lazy"
                allowFullScreen
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_GOOGLE_MAPS}
    &q=Absolute+Hardwood+Flooring+Co`}
              ></iframe>
            </div>
          </BlockSecond>
        </>
      )}
    />
  )
}

export default Contact

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-care-and-maintenance-js": () => import("./../../../src/pages/care-and-maintenance.js" /* webpackChunkName: "component---src-pages-care-and-maintenance-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-estimates-js": () => import("./../../../src/pages/estimates.js" /* webpackChunkName: "component---src-pages-estimates-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-on-site-finishing-js": () => import("./../../../src/pages/on-site-finishing.js" /* webpackChunkName: "component---src-pages-on-site-finishing-js" */),
  "component---src-pages-preparation-list-js": () => import("./../../../src/pages/preparation-list.js" /* webpackChunkName: "component---src-pages-preparation-list-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-warranty-policy-js": () => import("./../../../src/pages/warranty-policy.js" /* webpackChunkName: "component---src-pages-warranty-policy-js" */),
  "component---src-templates-flooring-js": () => import("./../../../src/templates/flooring.js" /* webpackChunkName: "component---src-templates-flooring-js" */)
}


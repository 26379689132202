import React from "react"
import LayoutAlt from "../components/layoutAlt"
import tw, { styled } from "twin.macro"
import BlockContent from "@sanity/block-content-to-react"
import { PageContain } from "../components/page-contain"
import SEO from "../components/seo"
import { graphql } from 'gatsby'

const Header1 = styled.h1`
  margin-top: 250px;
  text-align: center;
`

const Header2 = styled.h2`
  text-align: center;
`

const Prep = ({ data }) => {
  const serializers = {
    types: {
      justContent: props => <>{props.node.description}</>,
    },
  }
  return (
    <LayoutAlt>
      <SEO
        title={data.sanityPrep.seo && data.sanityPrep.seo.title}
        keywords={data.sanityPrep.seo && data.sanityPrep.seo.keywords}
        website={data.sanityPrep.seo && data.sanityPrep.seo.website}
        description={data.sanityPrep.seo && data.sanityPrep.seo.description}
        image={
          data.sanityPrep.seo.socialShareImage.asset &&
          data.sanityPrep.seo.socialShareImage.asset.url
        }
      />
      <PageContain>
        <Header1>{data.sanityPrep && data.sanityPrep.title}</Header1>
        <Header2>{data.sanityPrep && data.sanityPrep.subTitle}</Header2>
        <BlockContent
          blocks={data.sanityPrep._rawContent}
          serializers={serializers}
        />
      </PageContain>
    </LayoutAlt>
  )
}

export default Prep

export const query = graphql`
  query PrepPageQuery {
    sanityPrep {
      _rawContent
      subTitle
      title
      seo {
        website
        title
        description
        keywords
        socialShareImage {
          asset {
            url
          }
        }
      }
    }
  }
`

import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import styled, { keyframes } from "styled-components"
import About from "../components/about"
// import Faq from "../components/faq"
import Contact from "../components/contact"
import Flooring from "../components/flooring"
import FlooringDescription from "../components/flooringDescription"
import Installation from "../components/installation"
import Layout from "../components/layout"
import Reviews from "../components/reviews"
// import Quote from "../components/quote"
import SEO from "../components/seo"
import Typical from "react-typical"

const Wrapper = styled.section`
  position: relative;
`

const animation = keyframes`
  0% { opacity: 0; transform: translateY(-100px) skewX(10deg) skewY(10deg) rotateZ(30deg); filter: blur(10px); }
  25% { opacity: 1; transform: translateY(0px) skewX(0deg) skewY(0deg) rotateZ(0deg); filter: blur(0px); }
  75% { opacity: 1; transform: translateY(0px) skewX(0deg) skewY(0deg) rotateZ(0deg); filter: blur(1px); }
  100% { opacity: 0; transform: translateY(-100px) skewX(10deg) skewY(10deg) rotateZ(30deg); filter: blur(10px); }
`

const StaticText = styled.div`
  position: absolute;
  top: 300px;
  h1 {
    color: #fff;
    font-size: 4rem;
  }
  span,
  h2 {
    color: #fff;
    font-size: 7rem;
    font-weight: 900;
    margin-top: 50px;
    @media (max-width: 720px) {
      margin-top: 15px;
      font-size: 5rem;
    }
  }
  ${"" /* h1,
  h2,
  span {
    @media (max-width: 720px) {
      font-size: 5rem;
    }
  } */}
  @media (max-width: 720px) {
    width: 90%;
    ${"" /* left: 50%; */}
    margin-left: -45%;
    top: 200px;
  }
  width: 50%;
  text-align: center;
  left: 50%;
  margin-left: -25%;
`

const Animate = styled.span`
  display: inline-block;

  span {
    opacity: 0;
    display: inline-block;
    animation-name: ${animation};
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-fill-mode: forwards;
    ${"" /* animation-iteration-count: infinite; */}
    font-weight: bold;
  }
  span:nth-child(1) {
    animation-delay: 0.1s;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.3s;
  }
  span:nth-child(4) {
    animation-delay: 0.4s;
  }
  span:nth-child(5) {
    animation-delay: 0.5s;
  }
`

const Hero = styled.section`
  position: relative;
  margin-top: 100px;
  h2 {
    padding: 30px 30px 0 30px;
    margin: 0;
  }
  .quotey {
    position: absolute;
    top: 100px;
    width: 150px;
    background: #23395b;
    left: 50%;
    margin-left: -75px;
    color: #ffffff;
    border-radius: 30px;
    padding: 10px;
    text-align: center;
  }
  @media (min-width: 1024px) {
    display: grid;
    position: absolute;
    grid-template-columns: 600px auto;
    top: 0;
    margin: 200px 0;
    img {
      width: 100%;
    }
    .animateWrap {
      .quotey {
        position: relative;
        margin-left: 50px;
        left: 0;
        align-self: center;
        height: 50px;
        display: inline-block;
        width: 225px;
        border: none;
      }
    }
    height: 400px;
    h2 {
      height: 300px;
      font-weight: 900;
      color: #ffffff;
      line-height: 42px;
      align-self: center;
      width: 400px;
      display: block;
    }
  }
  @media (min-width: 1200px) {
    grid-template-columns: 700px auto;
    .animateWrap {
      h2 {
        width: 425px;
      }
    }
  }
  @media (min-width: 1440px) {
    grid-template-columns: 900px auto;
    .animateWrap {
      h2 {
        width: 520px;
      }
    }
  }
  @media (min-width: 1920px) {
    grid-template-columns: 900px auto;
    .animateWrap {
      h2 {
        width: 700px;
      }
    }
  }
`

const SplitHero = styled.section`
  display: none;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 40% 60%;
    @media (max-width: 1560px) {
      grid-template-columns: 25% 75%;
    }
    height: 75vh;
    @media (max-width: 1000px) {
      background: #cabfab;
    }
    div {
      &:last-child {
        background: #cabfab;
      }
    }
  }
`

const Welcome = styled.div`
  text-align: center;
  margin-top: 100px;
  h1 {
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 50px;
  }
  .quotey {
    display: grid;
    align-items: center;
    p {
      color: #41444b;
      font-size: 28px;
      font-weight: bold;
      height: 60px;
    }
    //border-radius: 30px;
    padding: 15px;
    text-align: center;
    margin: 0 15px;
    //box-shadow: 2px 2px 1px #000000;
    width: 200px;
    &:hover {
      box-shadow: none;
    }
    img {
      margin: 0 auto;
    }
  }
`

const QuoteGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 1200px;
  margin: 0 auto;
  align-items: center;
  justify-items: center;
  span {
    background: #cabfab;
    height: 150px;
    padding: 20px 0;
    border-radius: 15px;
  }
  @media (max-width: 1280px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .quotey {
      margin-bottom: 25px;
    }
  }
`

const PointerAbout = styled.div`
  height: 95px;
`

const PointerProducts = styled.div`
  height: 95px;
`

const PointerInstall = styled.div`
  transform: translateY(-95px);
`
const PointerFaq = styled.div`
  transform: translateY(-95px);
`
const PointerContact = styled.div`
  transform: translateY(-95px);
`
const PointerQuote = styled.div`
  transform: translateY(-95px);
`

const PointerReview = styled.div`
  transform: translateY(-95px);
`

const CarouselWrapper = styled(Carousel)`
  .gatsby-image-wrapper {
    height: 100vh;
  }
  @media (min-width: 1200px) {
    height: 100vh;
  }
  @media (max-width: 600px) {
    height: 100vh;
  }
  img {
    height: 100vh;
    object-fit: cover;
  }
  .slide .legendH {
    position: absolute;
    text-align: center;
    bottom: 50%;
    background: none;
    color: #fff;
    opacity: 1;
    font-size: 80px;
    width: 50%;
    left: 50%;
    margin-left: -25%;
  }
  .slide .legendP {
    position: absolute;
    text-align: center;
    bottom: 40%;
    background: none;
    color: #fff;
    opacity: 1;
    font-size: 50px;
    width: 50%;
    left: 50%;
    margin-left: -25%;
  }
  .animateWrap {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 1000px;
    margin-left: -500px;
    height: 100px;
    @media (max-width: 1100px) {
      width: 800px;
      margin-left: -400px;
    }
    @media (max-width: 700px) {
      width: 500px;
      margin-left: -250px;
    }
    @media (max-width: 550px) {
      width: 300px;
      margin-left: -150px;
    }
    h2 {
      font-size: 80px;
      color: #fff;
      line-height: 75px;
      @media (max-width: 1100px) {
        font-size: 60px;
      }
      @media (max-width: 700px) {
        font-size: 40px;
      }
      @media (max-width: 550px) {
        line-height: 36px;
        font-size: 34px;
      }
    }
  }
  .control-next {
    opacity: 1 !important;
    &::before {
      background-image: url("/arrow-right.png");
      border: none !important;
      width: 39px;
      height: 66px;
      @media (max-width: 550px) {
        transform: scale(0.5, 0.5);
      }
    }
  }
  .control-prev {
    opacity: 1 !important;
    &::before {
      background-image: url("/arrow-left.png");
      border: none !important;
      width: 39px;
      height: 66px;
      @media (max-width: 550px) {
        transform: scale(0.5, 0.5);
      }
    }
  }
`

// const Calendly = styled.div`
//   height: 800px;
//   margin-top: 100px;
// `

const Header = styled.div`
  h1 {
    color: #fff;
  }
`

const CalendlyWrapper = styled.div`
  height: 1350px;
  @media (min-width: 1200px) {
    height: 1200px;
  }
`

const ButtonBook = styled.button`
  align-items: center;
  ${"" /* background-image: linear-gradient(144deg, #23395b, #5b42f3 50%, #4ddb58); */}
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 8px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  width: 325px;
  margin: 0 auto;
  &:hover {
    outline: none;
  }
  span {
    background-color: ${props => props.buttonBgColor};
    padding: 16px 24px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: 300ms;
    &:hover {
      background: none;
      color: ${props => props.buttonBgColor};
      border: 2px solid ${props => props.buttonBgColor};
    }
  }
`

const BookWrapper = styled.div`
  height: 200px;
  display: grid;
  align-items: center;
`

const Loader = styled.div`
  width: 48px;
  height: 48px;
  border: 5px solid #cabfab;
  border-bottom-color: #23395b;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 50px auto;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

// const isBrowser = typeof window !== "undefined"

const IndexPage = ({ data }) => {
  // useEffect(() => {
  //   window.Calendly.initInlineWidget({
  //     url: "https://calendly.com/absolute-hardwood",
  //     parentElement: document.getElementById("bookingjs"),
  //     prefill: {},
  //     utm: {},
  //   })
  // }, [])

  const [book, setBook] = useState(false)
  const [loading, setLoading] = useState(true)

  const isBrowser = typeof window !== `undefined`

  const frameload = () => {
    console.log("test loading")
    setLoading(false)
  }

  const [text, setText] = useState(
    data.sanityHeader.animatedIntroText[0].split("")
  )

  const [text2, setText2] = useState(data.sanityHeader.animatedIntroText[0])

  const [countUp, setCountUp] = useState(0)

  useEffect(() => {
    const id = setTimeout(() => {
      if (countUp === data.sanityHeader.animatedIntroText.length - 1) {
        setCountUp(0)
      } else {
        setCountUp(prev => prev + 1)
      }
    }, 3500)

    return () => {
      clearTimeout(id)
    }
  }, [countUp])

  useEffect(() => {
    setText(data.sanityHeader.animatedIntroText[countUp].split(""))
    setText2(data.sanityHeader.animatedIntroText[countUp])
  }, [countUp])

  return (
    <Layout>
      <SEO
        title={data.sanityHeader.seo.title}
        website={data.sanityHeader.seo.website}
        description={data.sanityHeader.seo.description}
        keywords={data.sanityHeader.seo && data.sanityHeader.seo.keywords}
        image={data.sanityHeader.seo.socialShareImage.asset.url}
      />
      <Wrapper>
        <CarouselWrapper
          infiniteLoop={true}
          showIndicators={false}
          showThumbs={false}
          swipeable={false}
        >
          {data.sanityHeader.slideCards.map((item, i) => {
            return (
              <div key={i}>
                <GatsbyImage image={item.image.asset.gatsbyImageData} />
                {/* {i < 1 && (
                  <div className="animateWrap">
                    <Typical
                      steps={[item.cardTitle, 600, item.cardDescription, 100]}
                      loop={1}
                      wrapper="h2"
                    />
                  </div>
                )} */}
                {i < 1 && (
                  <StaticText>
                    <h1>{data.sanityHeader.staticIntroText}</h1>
                    {/* <Animate>
                      {text.map((item, index) => (
                        <span key={Math.random()}>{item}</span>
                      ))}
                    </Animate> */}

                    <Typical steps={[text2, 600]} loop={1} wrapper="h2" />
                  </StaticText>
                )}

                {/* <h1 className="legendH">
                  <a href={`#${item.pageLink}`}>{item.cardTitle}</a>
                </h1>
                <p className="legendP">{item.cardDescription}</p> */}
              </div>
            )
          })}
        </CarouselWrapper>
        {/* <Welcome>
          <h1>{data.sanityHeader.cta}</h1>
          <QuoteGrid>
            <Link className="quotey" to="/estimates">
              <p>Request A Quote</p>
              <span>
                <img src="/quote.svg" />
              </span>
            </Link>
            <Link className="quotey" to="/#contact">
              <p>Contact Us</p>
              <span>
                <img src="/contact.svg" />
              </span>
            </Link>
            <Link className="quotey" to="/#appointment">
              <p>Book an appointment</p>
              <span>
                <img src="/book.svg" />
              </span>
            </Link>
          </QuoteGrid>
        </Welcome> */}
      </Wrapper>
      <div></div>
      <PointerAbout id="about" />
      <About />
      <PointerProducts id="products" />
      <Flooring>
        <FlooringDescription />
      </Flooring>
      <PointerInstall id="install" />

      <Installation />
      {/* <PointerFaq id="faq" />
      <Faq /> */}
      <PointerContact id="contact" />
      <Contact />
      <PointerReview id="testimonials" />

      <Reviews />
      <PointerQuote id="appointment" />
      {/* <Quote /> */}
      <Header className="header">
        <h1>{data.sanityBookAppointment.topTitle}</h1>
      </Header>
      {/* <Calendly id="bookingjs" /> */}
      {!book && (
        <BookWrapper>
          <ButtonBook
            buttonBgColor={data.sanityBookAppointment.buttonBgColor}
            onClick={() => setBook(true)}
          >
            <span>{data.sanityBookAppointment.buttonTitle}</span>
          </ButtonBook>
        </BookWrapper>
      )}
      {book && (
        <>
          {loading && <Loader />}
          <CalendlyWrapper>
            <iframe
              onLoad={frameload}
              src="https://calendly.com/absolute-hardwood"
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          </CalendlyWrapper>
        </>
      )}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    sanityHeader {
      staticIntroText
      animatedIntroText
      slideCards {
        _key
        cardTitle
        pageLink
        cardDescription
        image {
          asset {
            url
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      IntroH1
      IntroH2
      cta
      seo {
        description
        title
        website
        keywords
        socialShareImage {
          asset {
            url
          }
        }
      }
      basicImage {
        asset {
          gatsbyImageData(width: 650)
        }
      }
    }
    sanityBookAppointment {
      buttonTitle
      buttonBgColor
      topTitle
    }
    file(relativePath: { eq: "one.jpg" }) {
      publicURL
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

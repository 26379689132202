import React from "react"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Controller } from "react-hook-form"
import tw, { styled } from "twin.macro"

const FormStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 50%;
  margin: 0 auto;
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 600px) {
    width: initial;
  }
  grid-column-gap: 100px;
  label {
    font-size: 28px;
    font-weght: 900;
  }
  input,
  select,
  textarea {
    margin: 25px 0;
    background: lightgrey;
    padding: 15px;
  }
`

const Text = styled.div`
  display: grid;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 600px) {
    width: 300px;
  }
`

const Checkbox = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  width: 50%;
  grid-column-gap: 15px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
  div {
    label {
      font-size: 18px;
      font-weight: normal;
      height: 40px;
    }
    display: grid;
    gird-template-columns: 1fr 1fr;
    justify-items: left;
    input {
      width: 25px;
      height: 25px;
    }
  }
`

export default function FormFinal({ registerFunction, controlFunction, path }) {
  return (
    <>
      {/* <h1>{path}</h1> */}
      <h2>Home Owner Contact Info</h2>
      <FormStyles>
        <div>
          <Text>
            <label>First Name</label>

            <input
              type="text"
              placeholder="First Name"
              ref={registerFunction({ required: true })}
              name="firstName"
            />
          </Text>
          <Text>
            <label>Last Name</label>
            <input
              type="text"
              placeholder="Last Name"
              ref={registerFunction({ required:true})}
              name="lastName"
            />
          </Text>
          <Text>
            <label>Primary Phone Number</label>
            <input
              type="tel"
              placeholder="Primary Phone Number"
              ref={registerFunction({ required:true})}
              name="number"
            />
          </Text>
          <Text>
            <label>Alternate Phone Number</label>
            <input
              type="tel"
              placeholder="Alternate Phone Number"
              ref={registerFunction}
              name="alternateNumber"
            />
          </Text>
          <Text>
            <label>Email</label>
            <input
              type="email"
              placeholder="Email"
              ref={registerFunction({ required: true })}
              name="email"
            />
          </Text>
          <Text>
            <label>City</label>
            <input
              type="text"
              placeholder="City"
              ref={registerFunction}
              name="city"
            />
          </Text>
          <Text>
            <label>Street Address</label>
            <input
              type="text"
              placeholder="Street Address"
              ref={registerFunction}
              name="street"
            />
          </Text>
          <Text>
            <label>Province</label>
            <select name="province" ref={registerFunction}>
              <option value="AB">Alberta</option>
              <option value="BC">British Columbia</option>
              <option value="MB">Manitoba</option>
              <option value="NB">New Brunswick</option>
              <option value="NL">Newfoundland and Labrador</option>
              <option value="NS">Nova Scotia</option>
              <option value="ON">Ontario</option>
              <option value="PE">Prince Edward Island</option>
              <option value="QC">Quebec</option>
              <option value="SK">Saskatchewan</option>
              <option value="NT">Northwest Territories</option>
              <option value="NU">Nunavut</option>
              <option value="YT">Yukon</option>
            </select>
          </Text>
          <Text>
            <label>Postal Code</label>
            <input
              type="text"
              placeholder="Postal COde"
              ref={registerFunction}
              name="postal"
            />
          </Text>
          <Text>
            <label>Preferred Method of Contact</label>
            <Checkbox>
              <div>
                <label>Email</label>
                <input
                  type="checkbox"
                  name="contact-email"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>Phone</label>
                <input
                  type="checkbox"
                  name="contact-phone"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>Text</label>
                <input
                  type="checkbox"
                  name="contact-text"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>Carrier pigeon</label>
                <input
                  type="checkbox"
                  name="contact-pigeon"
                  ref={registerFunction}
                />
              </div>
            </Checkbox>
          </Text>
          <Text>
            <label>How did you find us?</label>
            <select name="how-did-you-find-us" ref={registerFunction}>
              <option value="Facebook">Facebook</option>
              <option value="Twitter">Twitter</option>
              <option value="Instagram">Instagram</option>
              <option value="Houzz">Houzz</option>
              <option value="Pinterest">Pinterest</option>
              <option value="Google Search">Google Search</option>
              <option value="Referral">Referral</option>
              <option value="Repeat Client">Repeat Client</option>
              <option value="Custom Home Builder">Custom Home Builder</option>
              <option value="Driving By">Driving By</option>
              <option value="BNI">BNI</option>
            </select>
          </Text>
        </div>
        <div>
          <Text>
            <label>Product Type</label>
            <Checkbox>
              <div>
                <label>Hardwood Solid</label>
                <input
                  type="checkbox"
                  name="type-hardwood-solid"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>Hardwood ENG</label>
                <input
                  type="checkbox"
                  name="type-hardwood-eng"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>LVT/LVP</label>
                <input
                  type="checkbox"
                  name="type-lvt-lvp"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>Tile</label>
                <input
                  type="checkbox"
                  name="type-tile"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>Cork/Leather</label>
                <input
                  type="checkbox"
                  name="type-cork-leather"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>Laminate</label>
                <input
                  type="checkbox"
                  name="type-laminate"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>Carpet</label>
                <input
                  type="checkbox"
                  name="type-carpet"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>Refinishing</label>
                <input
                  type="checkbox"
                  name="type-refinishing"
                  ref={registerFunction}
                />
              </div>
            </Checkbox>
          </Text>
          <Text>
            <label>Other Products</label>
            <input
              type="text"
              placeholder="Other"
              ref={registerFunction}
              name="type-other"
            />
          </Text>
          <Text>
            <label>Product Name and Description</label>
            <input
              type="text"
              placeholder="Product Name and Description"
              ref={registerFunction}
              name="productNameDescription"
            />
          </Text>
          <Text>
            <label>Square Footage</label>
            <input
              type="text"
              placeholder="Square Footage"
              ref={registerFunction}
              name="squareFootage"
            />
          </Text>
          <Text>
            <label>Accessories</label>
            <Checkbox>
              <div>
                <label>Nosings</label>
                <input
                  type="checkbox"
                  name="access-nosings"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>Vents</label>
                <input
                  type="checkbox"
                  name="access-vents"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>Transitions</label>
                <input
                  type="checkbox"
                  name="access-transitions"
                  ref={registerFunction}
                />
              </div>
              <div>
                <label>Underlay</label>
                <input
                  type="checkbox"
                  name="access-underlay"
                  ref={registerFunction}
                />
              </div>
            </Checkbox>
          </Text>
          <Text>
            <label>Other Accessories</label>
            <input
              type="text"
              placeholder="Other"
              ref={registerFunction}
              name="access-other"
            />
          </Text>

          <Text>
            <label>Installation Date</label>

            <Controller
              as={ReactDatePicker}
              control={controlFunction}
              valueName="selected" // DateSelect value's name is selected
              onChange={([selected]) => selected}
              name="ReactDatepicker"
              className="input"
              placeholderText="Select date"
            />
          </Text>
          <Text>
            <label>Comments & special requests</label>
            <textarea
              placeholder="Comments Special Requests"
              ref={registerFunction}
              name="comments"
            ></textarea>
            {/* <input
              type="text"
              placeholder="Comments Special Requests"
              ref={registerFunction}
              name="comments"
            /> */}
          </Text>
        </div>
      </FormStyles>
    </>
  )
}

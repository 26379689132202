import React from "react"
import LayoutAlt from "../components/layoutAlt"
import tw, { styled } from "twin.macro"
import BlockContent from "@sanity/block-content-to-react"
import { PageContain } from "../components/page-contain"
import SEO from "../components/seo"
import { graphql } from 'gatsby'

const Header1 = styled.h1`
  margin-top: 250px;
  text-align: center;
`

const Header2 = styled.h2`
  text-align: center;
  text-align: center;
  width: 75%;
  margin: 0 auto 25px auto;
`

const Warranty = ({ data }) => {
  const serializers = {
    types: {
      justContent: props => <>{props.node.description}</>,
    },
  }
  return (
    <LayoutAlt>
      <SEO
        title={data.sanityWarranty.seo && data.sanityWarranty.seo.title}
        keywords={data.sanityWarranty.seo && data.sanityWarranty.seo.keywords}
        website={data.sanityWarranty.seo && data.sanityWarranty.seo.website}
        description={
          data.sanityWarranty.seo && data.sanityWarranty.seo.description
        }
        image={
          data.sanityWarranty.seo.socialShareImage.asset &&
          data.sanityWarranty.seo.socialShareImage.asset.url
        }
      />
      <PageContain>
        <Header1>{data.sanityWarranty && data.sanityWarranty.title}</Header1>
        <Header2>{data.sanityWarranty && data.sanityWarranty.subTitle}</Header2>
        <BlockContent
          blocks={data.sanityWarranty._rawContent}
          serializers={serializers}
        />
      </PageContain>
    </LayoutAlt>
  )
}

export default Warranty

export const query = graphql`
  query WarrantyPageQuery {
    sanityWarranty {
      subTitle
      title
      _rawContent
      seo {
        website
        title
        description
        keywords
        socialShareImage {
          asset {
            url
          }
        }
      }
    }
  }
`

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { createGlobalStyle } from "styled-components"
import NavAlt from "./navAlt"
import "./layout.css"
import "../sass/base.scss"
import Footer from "./Footer"
import { FaEdge } from "react-icons/fa"
import LogRocket from "logrocket"

const url = typeof window !== "undefined" ? window.location.origin : ""

if (
  !url.includes(
    "http://localhost:8888",
    "https://absolute-hardwood-dev-static.netlify.app"
  )
) {
  LogRocket.init("qhlun0/absolute-hardwood")
}

const Header = styled.header`
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
`

const GlobalStyle = createGlobalStyle`
  body{
    h1{
      ${tw`lg:text-5xl`}
    }
    .header {
      background: #cabfab;
      text-align: center;
      padding: 100px;
      p {
        ${tw`w-3/4 lg:w-1/2`}
        margin: 0 auto;
      }
      @media (max-width: 650px) {
        padding: 25px 0;
      }
    }
    .header-alt {
      text-align: center;
      padding: 100px;
      p {
        width: 35%;
        margin: 0 auto;
      }
    }
    .paragraph {
      padding: 100px 50px;
    }
    
  }
`

const Main = styled.main`
  min-height: calc(100vh - 518px);
`

const FooterWrap = styled.div`
  height: 215px;
`

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Header>
        <NavAlt />
      </Header>
      <Main>{children}</Main>
      <FooterWrap>
        <Footer />
      </FooterWrap>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React, { useState } from "react"
import LayoutAlt from "../components/layoutAlt"
import styled from "styled-components"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import BlockContent from "@sanity/block-content-to-react"

const FaqWrapper = styled.section`
  padding: 100px 0;
  //background: #8d8d92;
  .faqContent {
    width: 50%;
    margin: 0 auto;
    padding: 100px;
    @media (max-width: 650px) {
      padding: 25px 0;
    }
    button {
      margin: 30px 0;
      padding: 20px;
      width: 100px;
      font-size: 3rem;
      border: none;
      background: #23395b;
      color: #ffffff;
    }
  }
  .all {
    color: #ffffff;
    font-size: 2rem;
    margin-top: 20px;
    display: inline-block;
  }
  .backgroundColor {
    background: #ffffff;
  }
`

const Answer = ({ question }) => {
  const [showQuestion, setShowQuestion] = useState(false)

  const click = () => {
    setShowQuestion(value => !value)
  }

  return (
    <>
      <h2>{question.question}</h2>
      {showQuestion ? (
        <button onClick={click}>-</button>
      ) : (
        <button onClick={click}>+</button>
      )}
      {showQuestion && (
        <BlockContent blocks={question.description.explanation} />
      )}
    </>
  )
}

const FaqPage = ({ data }) => {
  return (
    <LayoutAlt>
      <SEO
        title={data.sanityFaq.seo && data.sanityFaq.seo.title}
        keywords={data.sanityFaq.seo && data.sanityFaq.seo.keywords}
        website={data.sanityFaq.seo && data.sanityFaq.seo.website}
        description={data.sanityFaq.seo && data.sanityFaq.seo.description}
        image={
          data.sanityFaq.seo.socialShareImage.asset &&
          data.sanityFaq.seo.socialShareImage.asset.url
        }
      />
      <FaqWrapper>
        <div className="header backgroundColor">
          <h1>{data.sanityFaq.title}</h1>
          <p>{data.sanityFaq.subTitle}</p>
        </div>
        <div className="faqContent">
          {data.sanityFaq._rawQuestion.map(question => {
            return <Answer key={question._key} question={question} />
          })}
        </div>
      </FaqWrapper>
    </LayoutAlt>
  )
}

export default FaqPage

export const query = graphql`
  query FaqPageQuery {
    sanityFaq {
      seo {
        website
        title
        description
        keywords
        socialShareImage {
          asset {
            url
          }
        }
      }
      buttonTitle
      title
      subTitle
      _rawQuestion(resolveReferences: { maxDepth: 10 })
    }
  }
`

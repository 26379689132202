import React from "react"

export default function Status({
  captchaFailed,
  nextStage,
  failedEmail,
  retry,
}) {
  return (
    <>
      {captchaFailed ? (
        <>
          <p>Captcha Verification Failed</p>
          <button onClick={retry}>retry</button>
        </>
      ) : (
        <>
          {!nextStage ? (
            <div class="loader">Loading...</div>
          ) : (
            <>
              {failedEmail ? (
                <>
                  <h1>Email Failed Contact us here </h1>
                  <h2>
                    <a href="mailto:salesteam@absolutehardwood.ca">
                      salesteam@absolutehardwood.ca
                    </a>
                  </h2>
                  <h3>
                    Or Retry here <button onClick={retry}>retry</button>
                  </h3>
                </>
              ) : (
                <>
                  <h1>Succesfully submitted</h1>
                  <svg
                    class="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      class="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      class="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

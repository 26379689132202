import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import BlockContent from "@sanity/block-content-to-react"
import tw, { styled } from "twin.macro"

const AboutWrap = styled.section`
  ${tw`lg:grid lg:grid-cols-2 p-10 lg:p-20`}
  h1 {
    ${tw`text-center md:text-left`}
  }
  p {
    ${tw`lg:w-3/4`}
  }
  ul {
    list-style: disc;
  }
`

const About = () => {
  return (
    <StaticQuery
      query={graphql`
        query AboutQuery {
          sanityAbout {
            _rawAboutDesc(resolveReferences: { maxDepth: 10 })
            IntroH1
            basicImage {
              asset {
                gatsbyImageData(layout:FULL_WIDTH, width: 1200)
              }
            }
          }
          file(relativePath: { eq: "two.jpg" }) {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <AboutWrap>
          <div className="two">
            <h1>{data.sanityAbout.IntroH1}</h1>
            <BlockContent blocks={data.sanityAbout._rawAboutDesc.explanation} />
          </div>
          <div className="one">
            {data.sanityAbout.basicImage && (
              <GatsbyImage image={data.sanityAbout.basicImage.asset.gatsbyImageData} />
            )}
          </div>
        </AboutWrap>
      )}
    />
  )
}

export default About

import React from "react"
import LayoutAlt from "../components/layoutAlt"
import tw, { styled } from "twin.macro"
import { PageContain } from "../components/page-contain"
import BlockContent from "@sanity/block-content-to-react"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactTooltip from "react-tooltip"
import { Carousel } from "react-responsive-carousel"
import ImageSingleDesign from "../components/ImageSingleDesign"
import { Appointment } from "../templates/flooring"

const Header1 = styled.h1`
  margin-top: 250px;
  text-align: center;
`

const Header2 = styled.h2`
  text-align: center;
`

const Header3 = styled.h3`
  text-align: center;
`

const Designers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  text-align: center;
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
  img {
    border-radius: 50%;
    width: 100px !important;
    height: 100px !important;
  }
`
const Designers4 = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  text-align: center;
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
  img {
    border-radius: 50%;
    width: 100px !important;
    height: 100px !important;
  }
`
const DesignPhases = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  text-align: center;
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
  img {
    object-fit: cover !important;
    height: 400px !important;
    margin:0 25px !important;
  }
`

const GalleryModStyles = styled.div`
  position: fixed;
  //background: #acacacd4;
  z-index: 9999999999;
  width: 100%;
  height: 100vh;
  margin-left: -50%;
  left: 50%;
  top: 0px;
  padding: 0 !important;
`

const CWStyles = styled.div`
  button {
    background: #000;
    color: #fff;
    padding: 25px;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    width: 100px;
    text-align: center;
  }
  margin: 0 auto;
  padding: 0 !important;
  display: block;
  position: relative;
  width: 65% !important;
  @media (max-width: 1400px) {
    width: 80% !important;
  }
  @media (max-width: 1000px) {
    width: 90% !important;
  }
  @media (max-width: 750px) {
    width: 100% !important;
  }
  @media (max-width: 750px) {
    div {
      padding: 0 !important;
    }
  }
  img {
    height: auto !important;
  }
  div.carousel-root {
    padding: 0 !important;
  }
  .carousel .control-prev.control-arrow {
    left: 0;
    height: 100px;
    top: 400px !important;
    position: absolute;
    width: 100px;
    @media (max-width: 750px) {
      top: 200px !important;
    }
    @media (max-width: 450px) {
      top: 100px !important;
    }
  }

  .carousel .control-next.control-arrow {
    right: 0;
    height: 100px;
    top: 400px !important;
    position: absolute;
    width: 100px;
    @media (max-width: 750px) {
      top: 200px !important;
    }
    @media (max-width: 450px) {
      top: 100px !important;
    }
  }
  .carousel .control-arrow:before,
  .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 20px solid #ffffff00;
    border-bottom: 20px solid transparent;
    content: "";
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 40px solid #fff;
  }
  .carousel .control-next.control-arrow:before {
    border-left: 40px solid #fff;
  }
`

const Interior = ({ data }) => {
  const serializers = {
    types: {
      justContent: props => <>{props.node.description}</>,
    },
  }
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <LayoutAlt>
      <SEO
        title={data.sanityInterior.seo && data.sanityInterior.seo.title}
        keywords={data.sanityInterior.seo && data.sanityInterior.seo.keywords}
        website={data.sanityInterior.seo && data.sanityInterior.seo.website}
        description={
          data.sanityInterior.seo && data.sanityInterior.seo.description
        }
        image={
          data.sanityInterior.seo.socialShareImage.asset &&
          data.sanityInterior.seo.socialShareImage.asset.url
        }
      />
      <PageContain>
        <Header1>{data.sanityInterior.title}</Header1>
        <Header2>{data.sanityInterior.description}</Header2>
        <Header1>{data.sanityInterior.titleTwo}</Header1>
        <Designers>
          {data.sanityInterior.designer.map((designer, i) => (
            <div key={designer._key}>
              <Header3>{designer.name}</Header3>
              <GatsbyImage image={designer.image.asset.gatsbyImageData} />
              <p>{designer.bio}</p>
            </div>
          ))}
        </Designers>
        <DesignPhases>
          {data.sanityInterior.designPhases.map((phase, i) => (
            <>
              {/* <div key={phase._key}>
                <Header3>{phase.title}</Header3>
                <GatsbyImage
                  image={phase.image.asset.gatsbyImageData}
                  onClick={openModal}
                  // alt={
                  //   detailEntry.floorImage.asset.alt
                  //     ? detailEntry.floorImage.asset.alt
                  //     : ""
                  // }
                />
              </div> */}
              <ImageSingleDesign detailEntry={phase} />
              {/* <>
                {modalIsOpen && phase.imageGallery && (
                  <GalleryModStyles>
                    <>
                      <CWStyles>
                        <Carousel
                          infiniteLoop={true}
                          showIndicators={false}
                          showThumbs={false}
                          swipeable={false}
                        >
                          {phase.imageGallery.map((item, i) => (
                            <div key={Math.random()}>
                              <img src={item.asset.url} />
                              <p>
                                <button onClick={closeModal}>close</button>
                              </p>
                            </div>
                          ))}
                        </Carousel>
                      </CWStyles>
                    </>
                  </GalleryModStyles>
                )}
              </> */}
            </>
          ))}
        </DesignPhases>
        <Header1>{data.sanityInterior.titleThree}</Header1>
        <Designers4>
          {data.sanityInterior.designerTraining.map((designer, i) => (
            <div key={designer._key}>
              <Header3>{designer.name}</Header3>
              <GatsbyImage image={designer.image.asset.gatsbyImageData} />
              <p>{designer.bio}</p>
            </div>
          ))}
        </Designers4>
        {/* <Header3>{data.sanityInterior.titleTwo}</Header3>
        <BlockContent
          blocks={data.sanityInterior._rawContent.explanation}
          serializers={serializers}
        />  */}
      </PageContain>
      <Appointment
        textColor={data.sanityProductsUniversal.textColor}
        textColorHover={data.sanityProductsUniversal.textColorHover}
        bgColor={data.sanityProductsUniversal.buttonBgColor}
        bgColorHover={data.sanityProductsUniversal.buttonBgColorHover}
      >
        <Link to="/#appointment">
          {data.sanityProductsUniversal.bookAppointmentTitle}
        </Link>
      </Appointment>
    </LayoutAlt>
  )
}

export default Interior

export const query = graphql`
  query InteriorPageQuery {
    sanityProductsUniversal {
      bookAppointmentDesc
      bookAppointmentTitle
      buttonBgColor
      buttonBgColorHover
      textColor
      textColorHover
    }
    sanityInterior {
      designer {
        bio
        image {
          asset {
            gatsbyImageData(width: 150)
          }
        }
        _key
        name
      }
      designPhases {
        _key
        title
        image {
          asset {
            gatsbyImageData(width: 1000)
          }
        }
        imageGallery {
          asset {
            url
          }
          alt
        }
      }
      designerTraining {
        _key
        bio
        name
        image {
          asset {
            gatsbyImageData(width: 150)
          }
        }
      }
      seo {
        website
        title
        description
        keywords
        socialShareImage {
          asset {
            url
          }
        }
      }
      title
      description
      titleTwo
      titleThree
      _rawContent   
    }
  }
  
`

import React from "react"
import tw, { styled } from "twin.macro"

const FormStyles = styled.div`
  display: grid;
  label {
    font-size: 28px;
    font-weght: 900;
  }
  input,
  select {
    margin: 25px 0;
    background: lightgrey;
    padding: 15px;
  }
`

const Text = styled.div`
  display: grid;
  width: 33%;
  margin: 0 auto;
  @media (max-width: 600px) {
    width: 300px;
  }
`

export default function FormA({ registerFunction, path }) {
  return (
    <>
      {/* <h1>{path}</h1> */}
      <FormStyles>
        <Text>
          <label>Contractor/Builders Company Name</label>
          <input
            type="text"
            placeholder="Contractor/Builders Company Name"
            ref={registerFunction}
            name="contractorName"
          />
        </Text>
        <Text>
          <label>Comments/Notes</label>
          <input
            type="text"
            placeholder="Comments/Notes"
            ref={registerFunction}
            name="comments-notes"
          />
        </Text>
        {/* <Text>
          <label>Contractor or Builder Contact Number</label>
          <input
            type="tel"
            placeholder="Contractor or Builder Contact Number"
            ref={registerFunction}
            name="contractorNumber"
          />
        </Text>
        <Text>
          <label>Company Contacts Email</label>
          <input
            type="email"
            placeholder="Company Contacts Email"
            ref={registerFunction}
            name="contractorEmail"
          />
        </Text> */}
      </FormStyles>
    </>
  )
}

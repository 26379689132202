import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import styled from "styled-components"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"


const InstallationWrapper = styled.section`
  .header {
    p {
      color: #000;
    }
    h1 {
      color: #fff;
    }
  }
`

const Block = styled.article`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  height: 600px;
  @media (max-width: 1500px) {
    height: auto;
  }
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    height: 1130px;
  }
`

const Installation = () => {
  return (
    <StaticQuery
      query={graphql`
        query InstallationQuery {
          sanityInstallationServices {
            _rawDesc(resolveReferences: { maxDepth: 10 })
            title
            subTitle
            basicImage {
              asset {
                gatsbyImageData(layout:FULL_WIDTH, width: 1200)
              }
            }
          }
        }
      `}
      render={data => (
        <InstallationWrapper>
          <div className="header">
            <h1>{data.sanityInstallationServices.title}</h1>
            <p>{data.sanityInstallationServices.subTitle}</p>
          </div>
          <Block>
            <GatsbyImage image={data.sanityInstallationServices.basicImage.asset.gatsbyImageData}
            />
            <div className="paragraph">
              <BlockContent
                blocks={data.sanityInstallationServices._rawDesc.explanation}
              />
            </div>
          </Block>
        </InstallationWrapper>
      )}
    />
  )
}

export default Installation

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import BlockContent from "@sanity/block-content-to-react"

const FlooringDescWrap = styled.section`
  background: #cabfab;
  ${tw`p-5 lg:p-20`}
  h1,
  p {
    text-align: center;
    @media (max-width: 550px) {
      width: initial;
    }
    width: 50%;
    margin: 0 auto;
    color: #000;
  }
  h1 {
    padding-bottom: 25px;
    color: #fff;
  }
`

const FlooringDescription = () => {
  return (
    <StaticQuery
      query={graphql`
        query FlooringDescQuery {
          sanityFlooringHomePage {
            title
            _rawFlooringDesc(resolveReferences: { maxDepth: 10 })
          }
        }
      `}
      render={data => (
        <FlooringDescWrap>
          <h1>{data.sanityFlooringHomePage.title}</h1>
          <BlockContent
            blocks={data.sanityFlooringHomePage._rawFlooringDesc.explanation}
          />
        </FlooringDescWrap>
      )}
    />
  )
}

export default FlooringDescription

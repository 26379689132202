import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import StarRatingComponent from "react-star-rating-component"
import SEO from "../components/seo"
import { graphql } from 'gatsby'

const TestimonialsWrapper = styled.section`
  padding: 100px 0;
  .faqContent {
    width: 50%;
    margin: 0 auto;
    padding: 100px;
    button {
      margin: 30px 0;
      padding: 20px;
      width: 100px;
      font-size: 3rem;
      border: none;
      background: #23395b;
      color: #ffffff;
    }
  }
  .all {
    color: #ffffff;
    font-size: 2rem;
    margin-top: 20px;
    display: inline-block;
  }
  .backgroundColor {
    background: #ffffff;
  }
`

const ReviewsWrap = styled.section`
  max-width: 75%;
  margin: 100px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  text-align: center;
  @media (max-width: 1201px) {
    width: 950px;
    grid-template-columns: repeat(3, 250px);
  }
  @media (max-width: 1130px) {
    width: 750px;
    grid-template-columns: repeat(3, 200px);
  }
  @media (max-width: 900px) {
    width: 650px;
    grid-template-columns: repeat(2, 250px);
  }
  @media (max-width: 700px) {
    width: 75%;
    grid-template-columns: 1fr;
  }
  img {
    width: 50px;
    margin: 25px auto;
    display: block;
    border-radius: 50%;
  }
  .review {
    box-shadow: -2px 5px 10px grey;
    padding: 30px;
    border-radius: 20px;
    &:hover {
      box-shadow: 0px 5px 5px grey;
      cursor: pointer;
    }
  }
`

const Testimonials = ({ data }) => {
  let testimonialArray = [
    ...data.sanityTestimonials.testimonialObject,
    ...data.allGooglePlacesReview.edges,
  ]

  console.log(testimonialArray)

  return (
    <Layout>
      <SEO
        title={data.sanityTestimonials.seo && data.sanityTestimonials.seo.title}
        keywords={
          data.sanityTestimonials.seo && data.sanityTestimonials.seo.keywords
        }
        website={
          data.sanityTestimonials.seo && data.sanityTestimonials.seo.website
        }
        description={
          data.sanityTestimonials.seo && data.sanityTestimonials.seo.description
        }
        image={
          data.sanityTestimonials.seo.socialShareImage.asset &&
          data.sanityTestimonials.seo.socialShareImage.asset.url
        }
      />
      <TestimonialsWrapper>
        <div id="testimonials" className="header-alt">
          <h1>{data.sanityTestimonials.title}</h1>
          <p>{data.sanityTestimonials.subTitle}</p>
        </div>
        <ReviewsWrap>
          {testimonialArray.map(data => {
            return (
              <>
                {data.__typename === "SanityTestimonialObject" ? (
                  <div className="review">
                    <h3>{data.author_name}</h3>
                    <img
                      alt="avatar"
                      src={`https://unavatar.now.sh/${data.email}?fallback=https://fallbacks.s3.us-east-2.amazonaws.com/avatar.png`}
                    />
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      value={5}
                      editable={false}
                    />
                    <p>{data.text}</p>
                  </div>
                ) : (
                  <div className="review">
                    <h3>{data.node.author_name}</h3>
                    <img alt="avatar" src={data.node.profile_photo_url} />
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      value={5}
                      editable={false}
                    />
                    <h4>Review from Google</h4>

                    <p>{data.node.text}</p>
                  </div>
                )}
              </>
            )
          })}
        </ReviewsWrap>
      </TestimonialsWrapper>
    </Layout>
  )
}

export default Testimonials

export const query = graphql`
  query TestimonialsPageQuery {
    allGooglePlacesReview {
      __typename
      edges {
        node {
          __typename
          author_name
          profile_photo_url
          rating
          text
        }
      }
    }
    sanityTestimonials {
      seo {
        website
        title
        description
        keywords
        socialShareImage {
          asset {
            url
          }
        }
      }
      subTitle
      title
      buttonTitle
      testimonialObject {
        __typename
        text: content
        email
        show
        author_name: name
        _key
      }
    }
  }
`

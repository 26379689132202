import React from "react"
import LayoutAlt from "../components/layoutAlt"
import tw, { styled } from "twin.macro"
import SEO from "../components/seo"
import { graphql } from 'gatsby'

import BlockContent from "@sanity/block-content-to-react"
import { PageContain } from "../components/page-contain"

const Header1 = styled.h1`
  margin-top: 250px;
  @media (max-width: 650px) {
    margin-top: 115px;
  }
  text-align: center;
`

const Header2 = styled.h2`
  text-align: center;
  width: 75%;
  margin: 0 auto 25px auto;
`

const StyleWrap = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
`

const Estimates = ({ data }) => {
  const serializers = {
    types: {
      justContent: props => <>{props.node.description}</>,
    },
  }
  return (
    <LayoutAlt>
      <SEO
        title={data.sanityCare.seo && data.sanityCare.seo.title}
        keywords={data.sanityCare.seo && data.sanityCare.seo.keywords}
        website={data.sanityCare.seo && data.sanityCare.seo.website}
        description={data.sanityCare.seo && data.sanityCare.seo.description}
        image={
          data.sanityCare.seo.socialShareImage.asset &&
          data.sanityCare.seo.socialShareImage.asset.url
        }
      />
      <PageContain>
        <StyleWrap>
          <Header1>{data.sanityCare && data.sanityCare.title}</Header1>
          <Header2>{data.sanityCare && data.sanityCare.subTitle}</Header2>
          <BlockContent
            blocks={data.sanityCare._rawContent}
            serializers={serializers}
          />
        </StyleWrap>
      </PageContain>
    </LayoutAlt>
  )
}

export default Estimates

export const query = graphql`
  query CarePageQuery {
    sanityCare {
      subTitle
      title
      _rawContent
      seo {
        website
        title
        description
        keywords
        socialShareImage {
          asset {
            url
          }
        }
      }
    }
  }
`
